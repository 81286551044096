import { Action, Translation } from 'anketa-core';

export const generateActions = (savePath: string, changeListener: () => void) => {
  const aStore = new Action(`save_${savePath.split('event_')[1]}`, [
    new Translation('en', 'Save'),
    new Translation('de', 'Speichern'),
  ]);
  aStore.addChangeListener(changeListener);

  return aStore;
};
