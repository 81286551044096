import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useApi, useKeycloak } from '../../_functions';
import { useToast } from '../../_functions/toast';
import {
  AnketaContext,
  AnketaContextProvider,
  EvaluationContext,
} from '../../components/context_provider';
import {
  ArcticWolfCatalog,
  Bsi2023Catalog,
  newcustomerCatalog,
  OnboardingCatalog,
  OpsanioCatalog,
  VmwareCatalog,
} from '../catalogs';
import { EventDetectionCatalog } from '../../event';
import { AuditCatalog, Catalog } from 'anketa-core';
import moment from 'moment';
import {
  QuestionarieCatalogs,
  QuestionarieContextType,
  QuestionarieParams,
} from '../../_types/questionnaires';

export const QuestionarieContext = createContext<QuestionarieContextType | undefined>(undefined);

export const QuestionarieProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { Get, Post, Delete } = useApi();
  const { keycloak, profile } = useKeycloak();
  const { addToast } = useToast();
  const ctx = useContext(AnketaContext);
  const evalCtx = useContext(EvaluationContext);

  const [questionaries, _setQuestionaries] = useState<QuestionarieParams[]>([]);
  const [catalogs, _setCatalogs] = useState<string[]>([]);
  const [selectedQuestionarie, _setSelectedQuestionarie] = useState<QuestionarieParams | null>(
    null
  );
  const [trigger, setTrigger] = useState<number>(Date.now());

  const setSelectedQuestionarie = (c: string | undefined, q: string | undefined) => {
    const visibleQuestionarie = questionaries.find(
      (questionarie) =>
        questionarie.catalog === c && questionarie.name?.toLowerCase() === q?.toLowerCase()
    );
    if (visibleQuestionarie) {
      _setSelectedQuestionarie(visibleQuestionarie);
    } else {
      _setSelectedQuestionarie(null);
    }
  };
  const catalogChangeListener = () => {
    ctx.reevaluate();
    evalCtx.nextEvaluationId();
  };

  const getCatalog = async (): Promise<void> => {
    if (!keycloak?.token) {
      console.log(keycloak, keycloak?.token);
      addToast({
        title: 'Authentication Error',
        text: <p>Token is missing. Please log in again.</p>,
        color: 'danger',
        iconType: 'cross',
      });
    } else {
      try {
        const catalogItems = await Get({
          path: `v2/questionaries/catalog`,
          token: keycloak.token,
        });
        const { questionaries, catalog } = catalogItems.data;
        _setQuestionaries(questionaries);
        _setCatalogs(catalog);
      } catch (error) {
        console.error('Error fetching catalog:', error);
      }
    }
  };

  const getCatalogInstance: any = (c: string, q: string) => {
    let newCatalog = AuditCatalog.instance.clone();
    if (keycloak) {
      switch (c) {
        case QuestionarieCatalogs.NewCustomer:
          newCatalog = new newcustomerCatalog(q, catalogChangeListener, keycloak);
          break;
        case QuestionarieCatalogs.ArcticWolf:
          newCatalog = new ArcticWolfCatalog(q, catalogChangeListener, keycloak);
          break;
        case QuestionarieCatalogs.Opsanio:
          newCatalog = new OpsanioCatalog(q, catalogChangeListener, keycloak);
          break;
        case QuestionarieCatalogs.VMware:
          newCatalog = new VmwareCatalog(q, catalogChangeListener, keycloak);
          break;
        case QuestionarieCatalogs.BSIKatalog2023Edition:
          newCatalog = new Bsi2023Catalog(q, catalogChangeListener, keycloak);
          break;
        case QuestionarieCatalogs.Onboarding:
          newCatalog = new OnboardingCatalog(q, catalogChangeListener, keycloak);

          break;
        case QuestionarieCatalogs.Event:
          newCatalog = new EventDetectionCatalog(undefined, catalogChangeListener, keycloak);
          break;
      }
      return newCatalog;
    }
  };
  const createOrUpdate = (c: string, q: string, data?: any): void => {
    const plain = getCatalogInstance(c, q);
    let content = plain;
    if (data) {
      content = plain.factsToObject(data);
    }
    const params: QuestionarieParams = {
      id: `${c}:${q}`,
      name: q,
      content: content,
      catalog: c,
      updated_by: profile?.username || '',
      last_updated: moment().toISOString(),
    };
    Post({
      path: `v2/questionaries/save/${c}/${q}`,
      value: params,
      token: keycloak?.token,
    })
      .then(() => {
        setTrigger(Date.now());
        addToast({
          title: 'Gespeichertet!',
          text: (
            <p>
              Sie haben erfolgreich den {params.catalog}: {params.name} gespeichert
            </p>
          ),
          color: 'success',
          iconType: 'check',
        });
      })
      .catch((err) => {
        console.error(err);
        addToast({
          title: 'Fehler!',
          text: <p>{JSON.stringify(err)}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };
  const deleteQ = (c: string, q: string): void => {
    Delete({
      path: `v2/questionaries/catalog/${c}/${q}`,
      token: keycloak?.token,
    })
      .then(() => {
        setTrigger(Date.now());
        addToast({
          title: 'Gelöscht!',
          text: (
            <p>
              Sie haben erfolgreich den {c}: {q} gelöscht
            </p>
          ),
          color: 'success',
          iconType: 'check',
        });
      })
      .catch((err) => {
        console.error(err);
        addToast({
          title: 'Fehler!',
          text: <p>{JSON.stringify(err)}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };
  const currentCatalog: Catalog = useMemo(() => {
    let newCatalog = AuditCatalog.instance.clone();
    if (selectedQuestionarie) {
      if (keycloak) {
        newCatalog = getCatalogInstance(selectedQuestionarie.catalog, selectedQuestionarie.name);
        newCatalog.factsFromObject(selectedQuestionarie.content);
      }
    }
    return newCatalog;
  }, [selectedQuestionarie]);

  useEffect(() => {
    if (keycloak?.token) {
      getCatalog();
    }
  }, [keycloak, trigger]);

  return (
    <AnketaContextProvider locale={'en-us'} catalog={currentCatalog}>
      <QuestionarieContext.Provider
        value={{
          questionaries,
          catalogs,
          selectedQuestionarie,
          setSelectedQuestionarie,
          currentCatalog,
          createOrUpdate,
          deleteQ,
        }}
      >
        {children}
      </QuestionarieContext.Provider>
    </AnketaContextProvider>
  );
};

export const useQuestionarie = () => {
  const context = useContext<QuestionarieContextType | undefined>(QuestionarieContext);
  if (context === undefined) {
    throw new Error('useQuestionarie must be used within a QuestionarieProvider');
  }
  return context;
};
