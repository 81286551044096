import { removeNumbersFromKeys } from '../documentation';
import { calculateHostRange, cidrSubnet, generateMacAddress } from '../ip';
import {
  OpsanioAgent,
  OpsanioCommons,
  OpsanioK3sServer,
  OpsanioK3sServers,
  OpsanioKula,
  OpsanioValues,
  OpsanioVolumeSizes,
  OpsanioWireGuard,
  OpsNetwork,
} from './types';

export const getCpeCidr = (zahl: number) => {
  return zahl % 2 === 0;
};

const getCustomerNetwork = (data: any): OpsNetwork => {
  const cidr = data.network_setup !== null ? data.network_setup.value : '';
  const defaultGateway = calculateHostRange(cidr, 1);

  const network: OpsNetwork = {
    cidr,
    network: defaultGateway.networkAddress,
    prefix: parseInt(defaultGateway.prefix),
    subnetMask: defaultGateway.subnetMask,
    dns:
      data.dns_setup !== null
        ? data.dns_setup.value
        : defaultGateway.specificHost
        ? defaultGateway.specificHost
        : '',
    gateway:
      data.gateway_setup !== null
        ? data.gateway_setup.value
        : defaultGateway.specificHost
        ? defaultGateway.specificHost
        : '',
    interface: 'vlan_customer',
    sharedIp: data.shared_ip_setup !== null ? data.shared_ip_setup.value : '',
    vlan: data.vlan_setup !== null ? parseInt(data.vlan_setup.value) : 0,
  };
  return network;
};

const getCpeNetwork = (data: any): OpsNetwork => {
  const cidr = data.network_cpe !== null ? data.network_cpe.value : '';
  const defaultGateway = calculateHostRange(cidr, 1);

  const network: OpsNetwork = {
    cidr,
    network: defaultGateway.networkAddress,
    prefix: parseInt(defaultGateway.prefix),
    subnetMask: defaultGateway.subnetMask,
    interface: 'vlan_cpe',
    sharedIp: defaultGateway.specificHost !== null ? defaultGateway.specificHost : '',
    vlan: data.vlan_cpe !== null ? parseInt(data.vlan_cpe.value) : 0,
  };
  return network;
};

const getCpeANetwork = (data: any): OpsNetwork => {
  const cidr = data.network_cpe_a !== null ? data.network_cpe_a.value : '';
  const defaultGateway = calculateHostRange(cidr, 1);

  const network: OpsNetwork = {
    cidr,
    network: defaultGateway.networkAddress,
    prefix: parseInt(defaultGateway.prefix),
    subnetMask: defaultGateway.subnetMask,
    interface: 'vlan_cpe_a',
    vlan: data.vlan_cpe_a !== null ? parseInt(data.vlan_cpe_a.value) : 0,
  };
  return network;
};

const getCpeBNetwork = (data: any): OpsNetwork => {
  const cidr = data.network_cpe_b !== null ? data.network_cpe_b.value : '';
  const defaultGateway = calculateHostRange(cidr, 1);

  const network: OpsNetwork = {
    cidr,
    network: defaultGateway.networkAddress,
    prefix: parseInt(defaultGateway.prefix),
    subnetMask: defaultGateway.subnetMask,
    interface: 'vlan_cpe_b',
    vlan: data.vlan_cpe_b !== null ? parseInt(data.vlan_cpe_b.value) : 0,
  };
  return network;
};

const getExanioNetwork = (data: any): OpsNetwork => {
  const cidr = data.network_exanio !== null ? data.network_exanio.value : '';
  const defaultGateway = calculateHostRange(cidr, 0);

  const network: OpsNetwork = {
    cidr,
    network: defaultGateway.networkAddress,
    prefix: parseInt(defaultGateway.prefix),
    subnetMask: defaultGateway.subnetMask,
    dns: defaultGateway.specificHost ? defaultGateway.specificHost : '',
    gateway: defaultGateway.specificHost ? defaultGateway.specificHost : '',
    interface: 'vlan_exanio',
    vlan: data.vlan_exanio !== null ? parseInt(data.vlan_exanio.value) : 0,
  };
  return network;
};

const getTransferNetwork = (data: any): OpsNetwork => {
  const cidr = data.network_transfer !== null ? data.network_transfer.value : '';
  const defaultGateway = calculateHostRange(cidr, 1);

  const network: OpsNetwork = {
    cidr,
    network: defaultGateway.networkAddress,
    prefix: parseInt(defaultGateway.prefix),
    subnetMask: defaultGateway.subnetMask,
    interface: 'vlan_transfer',
    vlan: data.vlan_transfer !== null ? parseInt(data.vlan_transfer.value) : 0,
  };
  return network;
};

const getWireguard = (data: any): OpsanioWireGuard => {
  return {
    dns: data.wireguard_dns ? data.wireguard_dns.value : '',
    ip: data.wireguard_ip ? data.wireguard_ip.value : '',
    port: data.wireguard_port ? data.wireguard_port.value : 0,
    interface: 'wg0',
  };
};

const getAgents = (
  data: any,
  transfer: OpsNetwork,
  cpeA: OpsNetwork,
  cpeB: OpsNetwork,
  customer: OpsNetwork,
  commons: OpsanioCommons
): OpsanioAgent[] => {
  const agents: OpsanioAgent[] = [];
  const plainAgents: any[] = [];
  if (data) {
    Object.entries(data).forEach((agent: any, idx: number) => {
      const cleanedObject = removeNumbersFromKeys(agent[1]);
      plainAgents.push({
        ...cleanedObject.agent,
        customer_offset: (cleanedObject.agent.customer_offset = idx + 3),
      });
    });
  }

  plainAgents.forEach((agent, idx) => {
    const offset = idx + 1;
    const customerMacAddress = generateMacAddress(offset, '02:99:C6:00:00');
    const exanioMacAddress = generateMacAddress(offset, '06:99:C6:00:00');
    agents.push({
      offset,
      customer_offset: idx + 3,
      host: agent.setup !== null ? agent.setup.value : '',
      host_version: 1,
      redfisch: {
        ip: agent.redfish !== null ? agent.redfish.value : '',
        sn: agent.sn !== null ? agent.sn.value : '',
      },
      networks: {
        exanio: getCpeCidr(offset)
          ? calculateHostRange(cpeB.cidr, idx + 4).specificHost ?? ''
          : calculateHostRange(cpeA.cidr, idx + 4).specificHost ?? '',
        customer: calculateHostRange(customer.cidr, agent.customer_offset).specificHost ?? '',
        transfer: calculateHostRange(transfer.cidr, offset).specificHost ?? '',
        kubernetes: cidrSubnet('10.42.0.0/16', 8, idx + 3),
      },
      hostname: `${commons.opsanioFullStage}-a0-opsanio-00${offset}`,
      macs: {
        exanio: exanioMacAddress,
        customer: customerMacAddress,
      },
    });
  });

  return agents;
};

const getKula = (
  exanio: OpsNetwork,
  transfer: OpsNetwork,
  commons: OpsanioCommons
): OpsanioKula => {
  const exanioIp = calculateHostRange(exanio.cidr, 9).specificHost;
  const transferIp = calculateHostRange(transfer.cidr, 0).specificHost;
  return {
    exanioIp: exanioIp ? exanioIp : '',
    transferIp: transferIp ? transferIp : '',
    hostname: `${commons.opsanioFullStage}-m0-kula-001`,
  };
};

const getCommons = (customerEnv: any, common: any): OpsanioCommons => {
  const twoRoom =
    customerEnv.chr.two_room !== null && customerEnv.chr.two_room.value === '0' ? true : false;
  const darkSide =
    customerEnv.chr.kritis !== null && customerEnv.chr.kritis.value === '0' ? true : false;
  const networkSlot = common.network_slot !== null ? parseInt(common.network_slot.value) : 0;
  const agentCount = common.agent_count !== null ? parseInt(common.agent_count.value) : 0;
  const owner = common.owner !== null ? common.owner.value : 'opsanio';
  const project = common.project !== null ? common.project.value : '';
  const stage = common.stage !== null ? common.stage.value : '';
  const opsanioFullStage = `${owner}-${project}-${stage}`;
  const customer = customerEnv.header.name !== null ? customerEnv.header.name.value : '';
  return {
    twoRoom,
    darkSide,
    networkSlot,
    agentCount,
    owner,
    project,
    stage,
    opsanioFullStage,
    customer,
  };
};

const getK3sServers = (
  count: number,
  commons: OpsanioCommons,
  exanio: OpsNetwork
): OpsanioK3sServers => {
  const servers: OpsanioK3sServer[] = [];

  for (let i = 0; i < count; i++) {
    const hostname = `${commons.opsanioFullStage}-m0-k3server-00${i + 1}`;
    const kubernetes = cidrSubnet('10.42.0.0/16', 8, i);
    const exanioIp = calculateHostRange(exanio.cidr, 20 + i).specificHost ?? '';
    servers.push({ hostname, kubernetes, exanioIp });
  }
  return {
    loadBalancerIp: calculateHostRange(exanio.cidr, 19).specificHost ?? '',
    servers,
  };
};

export const parseOpsanioCatalog = (data: any) => {
  const commons = getCommons(data.basic, data.autonubil.commons);
  const customer = getCustomerNetwork(data.c_network_env.c_gp_setup);
  const cpe = getCpeNetwork(data.autonubil.cpe_net);
  const cpeA = getCpeANetwork(data.autonubil.cpe_net.cpe_a_net);
  const cpeB = getCpeBNetwork(data.autonubil.cpe_net.cpe_b_net);
  const exanio = getExanioNetwork(data.autonubil.exanio_net);
  const transfer = getTransferNetwork(data.autonubil.transfer_net);
  const agents = getAgents(data.autonubil.agents, transfer, cpeA, cpeB, customer, commons);
  const wireguard = getWireguard(data.autonubil.wg);
  const kritis =
    data.basic.chr.kritis !== null ? (data.basic.chr.kritis.value === '1' ? false : true) : false;
  const twoRoom =
    data.basic.chr.two_room !== null
      ? data.basic.chr.two_room.value === '1'
        ? false
        : true
      : false;
  const k3sServer = getK3sServers(3, commons, exanio);
  const networks: any = { customer, cpe, cpeA, cpeB, exanio, transfer };
  const kula: OpsanioKula = getKula(exanio, transfer, commons);
  const volumes: OpsanioVolumeSizes = {
    var: 50,
    varTmp: 25,
    home: 10,
    tmp: 25,
    root: 25,
    log: 100,
    logAudit: 25,
  };

  const values: OpsanioValues = {
    networks,
    wireguard,
    kritis,
    twoRoom,
    agents,
    kula,
    k3sServer,
    commons,
    volumes,
  };

  return values;
};
