import {
  Translation,
  Row,
  OpenQuestion,
  OptionsQuestion,
  NumericQuestion,
  CatalogObject,
  Group,
  ComplexQuestion,
} from 'anketa-core';
import { KeycloakInstance } from 'keycloak-js';
import { generateActions } from '../opsanio/actions';

export class ArcticWolfCatalog extends CatalogObject {
  private static _instance: ArcticWolfCatalog;

  public static get instance(): ArcticWolfCatalog {
    if (!ArcticWolfCatalog._instance) {
      throw new Error(
        'ArcticWolfCatalog instance has not been created. Use createInstance(name) method first.'
      );
    }
    return ArcticWolfCatalog._instance;
  }

  public static createInstance(
    name: string,
    changeListener: () => void,
    authCtx: KeycloakInstance
  ): ArcticWolfCatalog {
    if (!ArcticWolfCatalog._instance) {
      ArcticWolfCatalog._instance = new ArcticWolfCatalog(name, changeListener, authCtx);
    }
    return ArcticWolfCatalog._instance;
  }

  constructor(name: string, changeListener: () => void, authCtx: KeycloakInstance) {
    const id: string = name.toLowerCase().replace(/ /g, '-');
    super(id, [new Translation('en', name), new Translation('de', name)]);

    // HEADER
    const gHeader = new Group('basic', [
      new Translation('en', 'General data'),
      new Translation('de', 'Allgemeine Daten'),
    ]);
    const headerRow = new Row('header', [
      new Translation('en', 'Customer Profile'),
      new Translation('de', 'Kundensteckbrief'),
    ]);
    const companyName = new OpenQuestion('name', [
      new Translation('en', 'Company Name'),
      new Translation('de', 'Firmenname'),
    ]);
    companyName.setFactValue(name);
    headerRow.addChild(companyName);

    const customerContactRow = new Row('customer_contact_0', [
      new Translation('en', 'Customer Contact'),
      new Translation('de', 'Kundenkontakt'),
    ]);
    const generateExternal = () => {
      const oldTrigger: ComplexQuestion = customerContactRow.children.get(
        `user_customer_${customerContactRow.children.size - 1}`
      ) as ComplexQuestion;
      oldTrigger.removeChangeListener(generateExternal);

      const item = new ComplexQuestion(
        `user_customer_${customerContactRow.children.size}`,
        [
          new Translation('en', `Customer Contact ${customerContactRow.children.size}`),
          new Translation('de', `Kundenkontakt ${customerContactRow.children.size}`),
        ],
        'User'
      );
      item.addChangeListener(generateExternal);
      customerContactRow.addChild(item);
    };

    const customer = new ComplexQuestion(
      'user_customer_0',
      [new Translation('en', 'Customer Contact'), new Translation('de', 'Kundenkontakt')],
      'User'
    );
    customer.addChangeListener(generateExternal);
    customerContactRow.addChild(customer);

    headerRow.addChild(customerContactRow);

    const commentAreaContactPerson = new OpenQuestion('comment_acp_0', [
      new Translation('en', 'Comment'),
      new Translation('de', 'Kommentar'),
    ]);
    // commentAreaContactPerson.validator = new MarkdownValidator()
    headerRow.addChild(commentAreaContactPerson);

    gHeader.addChild(headerRow);
    this.addChild(gHeader);

    // LOCATIONS
    const gLocations = new Group('location_group', [
      new Translation('en', 'Locations'),
      new Translation('de', 'Standorte'),
    ]);
    const rLocations = new Row('location_row_0', [
      new Translation('en', 'Locations'),
      new Translation('de', 'Standorte'),
    ]);

    const generateLocation = () => {
      const oldTrigger: ComplexQuestion = gLocations.children
        .get(`location_row_${gLocations.children.size - 1}`)
        ?.children.get(`location_name`) as ComplexQuestion;
      oldTrigger.removeChangeListener(generateLocation);

      const rLocationsNew = new Row(`location_row_${gLocations.children.size}`, [
        new Translation('en', 'Locations'),
        new Translation('de', 'Standorte'),
      ]);

      const locationNameNew = new OpenQuestion(`location_name`, [
        new Translation('en', 'Location'),
        new Translation('de', 'Standort'),
      ]);
      locationNameNew.addChangeListener(generateLocation);

      rLocationsNew.addChild(locationNameNew);

      const locationLocalNetworkNew = new OpenQuestion(`location_local_network_access`, [
        new Translation('en', 'Localnetwork access'),
        new Translation('de', 'Lokaler Inetzugriff'),
      ]);
      rLocationsNew.addChild(locationLocalNetworkNew);

      const locationEmployeeNew = new NumericQuestion(`location_employee`, [
        new Translation('en', 'Count of users'),
        new Translation('de', 'Anzahl an Nutzern'),
      ]);
      rLocationsNew.addChild(locationEmployeeNew);

      const locationInternetSpeedNew = new NumericQuestion(`location_internet_speed`, [
        new Translation('en', 'Internet bandwidth'),
        new Translation('de', 'Internet-Bandwbreite'),
      ]);
      rLocationsNew.addChild(locationInternetSpeedNew);

      const locationFirewallsNew = new NumericQuestion(`location_firewall_count`, [
        new Translation('en', 'Count of firewalls'),
        new Translation('de', 'Anzahl an Firewalln'),
      ]);
      rLocationsNew.addChild(locationFirewallsNew);

      const locationFirewallHaNew = new OptionsQuestion(
        `location_firewall_ha`,
        [new Translation('en', 'Firewalls HA'), new Translation('de', 'Firewalls HA')],
        'default.options.yes_no'
      );
      rLocationsNew.addChild(locationFirewallHaNew);

      gLocations.addChild(rLocationsNew);
    };

    const locationName = new OpenQuestion('location_name', [
      new Translation('en', 'Location'),
      new Translation('de', 'Standort'),
    ]);
    locationName.addChangeListener(generateLocation);

    rLocations.addChild(locationName);

    const locationLocalNetwork = new OpenQuestion('location_local_network_access', [
      new Translation('en', 'Localnetwork access'),
      new Translation('de', 'Lokaler Inetzugriff'),
    ]);
    rLocations.addChild(locationLocalNetwork);

    const locationEmployee = new NumericQuestion('location_employee', [
      new Translation('en', 'Count of users'),
      new Translation('de', 'Anzahl an Nutzern'),
    ]);
    rLocations.addChild(locationEmployee);

    const locationInternetSpeed = new NumericQuestion('location_internet_speed', [
      new Translation('en', 'Internet bandwidth'),
      new Translation('de', 'Internet-Bandwbreite'),
    ]);
    rLocations.addChild(locationInternetSpeed);

    const locationFirewalls = new NumericQuestion('location_firewall_count', [
      new Translation('en', 'Count of firewalls'),
      new Translation('de', 'Anzahl an Firewalln'),
    ]);
    rLocations.addChild(locationFirewalls);

    const locationFirewallHa = new OptionsQuestion(
      'location_firewall_ha',
      [new Translation('en', 'Firewalls HA'), new Translation('de', 'Firewalls HA')],
      'default.options.yes_no'
    );
    rLocations.addChild(locationFirewallHa);

    gLocations.addChild(rLocations);
    this.addChild(gLocations);

    // GENERAL QUESTIONS
    const gGeneral = new Group('general_group', [
      new Translation('en', 'General'),
      new Translation('de', 'Allgemein'),
    ]);

    const azure = new NumericQuestion('azure', [
      new Translation('en', 'How many employees with an AD account are there in total?'),
      new Translation('de', 'Wie viele Mitarbeiter mit AD-Account gibt es insgesamt?'),
    ]);
    gGeneral.addChild(azure);

    const m365 = new NumericQuestion('m365', [
      new Translation('en', 'How many Office365 users?'),
      new Translation('de', 'Wie viele Office365 User?'),
    ]);
    gGeneral.addChild(m365);

    const salesforce = new NumericQuestion('salesforce', [
      new Translation('en', 'How many Salesforce users?'),
      new Translation('de', 'Wie viele Salesforce User?'),
    ]);
    gGeneral.addChild(salesforce);

    const box = new NumericQuestion('box', [
      new Translation('en', 'How many Box users?'),
      new Translation('de', 'Wie viele Box User?'),
    ]);
    gGeneral.addChild(box);

    const gSuite = new NumericQuestion('gsuite', [
      new Translation('en', 'How many G-Suite users?'),
      new Translation('de', 'Wie viele G-Suite User?'),
    ]);
    gGeneral.addChild(gSuite);

    const server = new NumericQuestion('server', [
      new Translation(
        'en',
        'How many servers are available in total (physical & virtual without hypervisor)?'
      ),
      new Translation(
        'de',
        'Wie viele Server sind insgesamt vorhanden (physisch & virtuell ohne Hypervisor)?'
      ),
    ]);
    gGeneral.addChild(server);

    const azureVm = new NumericQuestion('azurevm', [
      new Translation('en', 'How many VMs in Azure?'),
      new Translation('de', 'Wie viele VMs in Azure?'),
    ]);
    gGeneral.addChild(azureVm);

    const aws = new NumericQuestion('aws', [
      new Translation('en', 'How many AWS EC2 instances?'),
      new Translation('de', 'Wie viele AWS EC2 Instanzen?'),
    ]);
    gGeneral.addChild(aws);

    this.addChild(gGeneral);

    // Action Section
    this.addChild(generateActions(`arcticwolf_${id}`, changeListener, authCtx));
  }
}
