import React, { useState } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiMarkdownFormat,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { useNavigate, useParams } from 'react-router';
import { RouteQuestionarie } from '../../_functions/common';
import { useQuestionarie } from '../context';
import { KeycloakClient, KeycloakRolePrefix } from '../../_types/questionnaires';

export const CreateQuestionnaires = (): JSX.Element => {
  const { createOrUpdate } = useQuestionarie();

  const { catalog } = useParams();
  const navigate = useNavigate();
  const [questionarieName, setQuestionarieName] = useState<string>('');

  const closeAction = (): void => {
    setQuestionarieName('');
    navigate(`/${RouteQuestionarie}/`);
  };
  return (
    <EuiModal onClose={closeAction}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{catalog}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm component="form">
          {questionarieName.length > 31 && (
            <>
              <EuiPanel color="danger">Der name darf nicht länger als 31 Zeichen sein!</EuiPanel>
              <EuiSpacer />
            </>
          )}
          <EuiFormRow>
            <EuiFieldText
              min={1}
              max={31}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setQuestionarieName(event.target.value);
              }}
              placeholder="Name"
              isInvalid={questionarieName.length > 31}
            />
          </EuiFormRow>
          <EuiSpacer />
          <EuiPanel>
            <EuiMarkdownFormat>{`
Bitte denke daran die folgenden Rollen im Keycloak Client\n 
${KeycloakClient} anzulegen und den gewüschnten Rollen zuzuweisen!
- ${KeycloakRolePrefix}-${questionarieName}
  - Lese Rechte
- ${KeycloakRolePrefix}-${questionarieName}-admin 
  - Vollzugriff
          
          `}</EuiMarkdownFormat>
            <EuiText size="s" color="warning"></EuiText>
          </EuiPanel>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeAction}>Cancel</EuiButtonEmpty>
        <EuiButton
          isDisabled={!catalog || questionarieName.length < 1 || questionarieName.length > 31}
          fill
          onClick={() => {
            if (catalog) {
              createOrUpdate(catalog, questionarieName);
              navigate(`/${RouteQuestionarie}/${catalog}/${questionarieName}`);
            }
          }}
        >
          Save
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
