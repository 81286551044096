import React, { useEffect, useState } from 'react';
import {
  EuiButton,
  EuiCard,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiTextArea,
  EuiTitle,
} from '@elastic/eui';
import { QrReader } from 'react-qr-reader';
import { useToast } from '../_functions/toast';
import { EventDetectionQuestinary, VCardData } from './types';
import { EventDetectionCatalog } from './catalog';
import { PublicEventData, questionarieParams } from '../_types';
import moment from 'moment';
import { useApi, useNewsletter } from '../_functions';

export const QRCodeScanner = ({ publicData }: { publicData: PublicEventData }): JSX.Element => {
  const { addToast } = useToast();
  const { Post } = useApi();
  const { getEventUsers } = useNewsletter();

  const [isScanning, setIsScanning] = useState(false);

  const [customerFirstName, setCustomerFirstName] = useState<string>('');
  const [customerLastName, setCustomerLastName] = useState<string>('');
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [customerCompany, setCustomerCompany] = useState<string>('');

  const [userList, setUserList] = useState<any[]>([]);
  const [selectedUser, setSelectedUSer] = useState<EuiComboBoxOptionOption[]>([]);
  const [partnerContact, setPartnerContact] = useState<Array<EuiComboBoxOptionOption<string>>>([]);
  const [note, setNote] = useState<string>('');

  const cleanup = (): void => {
    setCustomerCompany('');
    setCustomerEmail('');
    setCustomerFirstName('');
    setPartnerContact([]);
    setCustomerLastName('');
    setNote('');
  };

  const onPartnerChange = (options: Array<EuiComboBoxOptionOption<string>>): void => {
    if (options.length > 0) {
      setPartnerContact(options);
    }
  };

  const onUserChangeChange = (options: Array<EuiComboBoxOptionOption<any>>): void => {
    if (options.length > 0) {
      const _user: any = options[0].value;
      if (_user.firstname !== null && _user.firstname !== undefined) {
        setCustomerFirstName(`${_user.firstname}`);
      }
      if (_user.lastname) {
        setCustomerLastName(`${_user.lastname}`);
      }
      if (_user.company_name !== null && _user.company_name !== undefined) {
        setCustomerCompany(`${_user.company_name}`);
      }
      if (_user.email !== null && _user.email !== undefined) {
        setCustomerEmail(`${_user.email}`);
      }
      setSelectedUSer(options);
    }
  };

  const submitForm = (): void => {
    const questinaryValues: EventDetectionQuestinary = {
      customer: {
        email: customerEmail,
        name: `${customerFirstName} ${customerLastName}`,
        organization: customerCompany,
      },
      message: note,
      partner: {
        contact: partnerContact[0].label,
        name: publicData.partner.name,
      },
      event: publicData.event.id,
    };
    const anketaQuestanarie = new EventDetectionCatalog(questinaryValues);
    const anketaQuestanarieObject = anketaQuestanarie.factsToObject();
    console.log(anketaQuestanarieObject);
    const params: questionarieParams = {
      id: `event:${anketaQuestanarie.id}`,
      name: `${publicData.partner.name} || ${customerEmail}`,
      content: anketaQuestanarieObject,
      catalog: `event`,
      last_updated: moment().toISOString(),
      updated_by: 'Event ',
    };

    Post({
      path: `questionaries/event/save/${anketaQuestanarie.id}`,
      value: {
        questionarie: params,
        user: {
          firstname: customerFirstName,
          lastname: customerLastName,
          email: customerEmail,
          company: customerCompany,
        },
        event: publicData.event.id,
        partnerId: partnerContact[0].value,
        message: note,
      },
    })
      .then((response) => {
        if (response.status === 201) {
          addToast({
            title: 'Daten Gespeichert',
            text: <p>Das anliegen des Kunden konnte erfolgrecih gespeichert werden!</p>,
            color: 'success',
            iconType: 'check',
          });
        } else {
          addToast({
            title: `[${response.status}] Fehler`,
            text: <p>{JSON.stringify(response.data)}</p>,
            color: 'danger',
            iconType: 'alert',
          });
        }
        cleanup();
      })
      .catch((err) => {
        addToast({
          title: `[500] Fehler`,
          text: <p>{JSON.stringify(err)}</p>,
          color: 'danger',
          iconType: 'alert',
        });
      });
  };

  const validSubmit = (): boolean => {
    if (partnerContact.length === 0) {
      return true;
    }
    if (!customerFirstName.trim()) {
      return true;
    } else if (!customerEmail.trim()) {
      return true;
    } else if (!note.trim()) {
      return true;
    } else if (!customerLastName.trim()) {
      return true;
    }
    return false;
  };

  const parseVCard = (vCardText: string): VCardData => {
    const fields: VCardData = {
      name: '',
      organization: '',
      email: '',
    };

    let lines = vCardText.replace('BEGIN:VCARD', '').replace('END:VCARD', '').split('\n');
    if (lines.length === 1) {
      lines = vCardText.replace('BEGIN:VCARD', '').replace('END:VCARD', '').split(' ');
    }
    let currentField = '';
    lines.forEach((line) => {
      if (line.startsWith('N:')) {
        fields.name = line.replace('N:', '').trim();
        currentField = 'name';
      } else if (line.startsWith('ORG:')) {
        fields.organization = line.replace('ORG:', '').trim();
        currentField = 'organization';
      } else if (line.startsWith('EMAIL:')) {
        fields.email = line.replace('EMAIL:', '').trim();
        currentField = 'email';
      } else if (line.trim() && currentField) {
        fields[currentField as keyof VCardData] += ` ${line.trim()}`;
      }
    });

    fields.name = fields.name.trim();
    fields.organization = fields.organization.trim();
    fields.email = fields.email.trim().replace(' "', '');

    setCustomerFirstName(fields.name.split(';')[1]);
    setCustomerLastName(fields.name.split(';')[0]);
    setCustomerEmail(fields.email);
    setCustomerCompany(fields.organization);
    return fields;
  };

  const handleScan = (result: string | null) => {
    if (result) {
      parseVCard(result);
      setIsScanning(false);
    }
  };

  const startScanning = () => {
    setIsScanning(!isScanning);
  };

  const fetchUsers = async () => {
    const _users = await getEventUsers(publicData.event.id);
    setUserList(_users);
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <>
      <EuiForm style={{ padding: '15px' }}>
        <EuiFormRow style={{ alignItems: 'center' }}>
          <EuiTitle>
            <h4>Kunde</h4>
          </EuiTitle>
        </EuiFormRow>
        <EuiFormRow label="" style={{ alignItems: 'center' }}>
          <EuiButton fill color="primary" iconType="symlink" onClick={startScanning} />
        </EuiFormRow>
        <EuiSpacer />
        {isScanning && (
          <>
            <EuiCard
              style={{ minWidth: '350px' }}
              title="Scan QR Code"
              description={
                <QrReader
                  onResult={(result, error) => {
                    if (result) handleScan(result?.getText() ?? null);
                  }}
                  constraints={{ facingMode: 'environment' }}
                />
              }
            />
            <EuiSpacer />
          </>
        )}
        <EuiFormRow label="Kunden Kontakt">
          <EuiFormRow label="">
            <EuiComboBox
              options={userList.map((user) => ({
                label: `${user.firstname} ${user.lastname}`,
                value: user,
              }))}
              onChange={onUserChangeChange}
              selectedOptions={selectedUser}
              singleSelection={{ asPlainText: true }}
              isClearable={true}
            />
          </EuiFormRow>
        </EuiFormRow>
        <EuiFormRow label="Vorname">
          <EuiFieldText
            value={customerFirstName}
            onChange={(e) => {
              setCustomerFirstName(e.target.value);
            }}
          />
        </EuiFormRow>
        <EuiFormRow label="Nachname">
          <EuiFieldText
            value={customerLastName}
            onChange={(e) => {
              setCustomerLastName(e.target.value);
            }}
          />
        </EuiFormRow>
        <EuiFormRow label="Email">
          <EuiFieldText
            value={customerEmail}
            onChange={(e) => {
              setCustomerEmail(e.target.value);
            }}
          />
        </EuiFormRow>
        <EuiFormRow label="Unternehmen">
          <EuiFieldText
            value={customerCompany}
            onChange={(e) => {
              setCustomerCompany(e.target.value);
            }}
          />
        </EuiFormRow>

        <EuiSpacer />
        <>
          <EuiFormRow style={{ alignItems: 'center' }}>
            <EuiTitle>
              <h4>{publicData.partner.name}</h4>
            </EuiTitle>
          </EuiFormRow>
          <EuiFormRow label="Ansprechpartner">
            <EuiFormRow label="">
              <EuiComboBox
                options={publicData.partner_employees.map((employee) => ({
                  label: `${employee.firstname} ${employee.lastname}`,
                  value: employee.id,
                }))}
                onChange={onPartnerChange}
                selectedOptions={partnerContact}
                singleSelection={{ asPlainText: true }}
                isClearable={true}
              />
            </EuiFormRow>
          </EuiFormRow>
          <EuiFormRow label="Notizen">
            <EuiTextArea
              value={note}
              onChange={(e) => {
                console.log(e.target.value);
                setNote(e.target.value);
              }}
            />
          </EuiFormRow>
        </>
        <EuiFormRow label="" style={{ alignItems: 'center' }}>
          <EuiFlexGrid direction="row">
            <EuiFlexItem grow={false}>
              <EuiButton
                size="s"
                color="primary"
                isDisabled={validSubmit()}
                onClick={() => {
                  submitForm();
                }}
              >
                Speichern
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                size="s"
                color="danger"
                onClick={() => {
                  cleanup();
                }}
              >
                Verwerfen
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGrid>
        </EuiFormRow>
      </EuiForm>
    </>
  );
};

export const AssignPresentation = ({
  eventId,
  partnerId,
  presentetionId,
  publicData,
}: {
  eventId: string;
  partnerId: string;
  presentetionId: string;
  publicData: PublicEventData;
}): JSX.Element => {
  const { addToast } = useToast();
  const { Post } = useApi();
  const { getEventUsers } = useNewsletter();

  const [selectedUser, setSelectedUSer] = useState<EuiComboBoxOptionOption[]>([]);
  const [userList, setUserList] = useState<any[]>([]);

  const submitForm = (
    firstname: string,
    lastname: string,
    email: string,
    companyName: string
  ): void => {
    const body = {
      email,
      firstname,
      lastname,
      companyName,
    };
    Post({
      path: `public/event/${eventId}/${partnerId}/${presentetionId}`,
      value: body,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          addToast({
            title: 'Daten Gespeichert',
            text: <p>Kunde wurde der Präsentation zu gewiesen</p>,
            color: 'success',
            iconType: 'check',
          });
        } else {
          addToast({
            title: `[${response.status}] Fehler`,
            text: <p>{JSON.stringify(response.data)}</p>,
            color: 'danger',
            iconType: 'alert',
          });
        }
      })
      .catch((err) => {
        addToast({
          title: `[500] Fehler`,
          text: <p>{JSON.stringify(err)}</p>,
          color: 'danger',
          iconType: 'alert',
        });
      });
  };

  const onUserChangeChange = (options: Array<EuiComboBoxOptionOption<any>>): void => {
    if (options.length > 0) {
      const _user: any = options[0].value;
      setSelectedUSer(options);

      submitForm(
        `${_user.firstname}`,
        `${_user.lastname}`,
        `${_user.email}`,
        `${_user.company_name}`
      );
    }
  };
  const parseVCard = (vCardText: string): VCardData => {
    const fields: VCardData = {
      name: '',
      organization: '',
      email: '',
    };

    let currentField = '';

    let lines = vCardText.replace('BEGIN:VCARD', '').replace('END:VCARD', '').split('\n');
    if (lines.length === 1) {
      lines = vCardText.replace('BEGIN:VCARD', '').replace('END:VCARD', '').split(' ');
    }

    lines.forEach((line) => {
      if (line.startsWith('N:')) {
        fields.name = line.replace('N:', '').trim();
        currentField = 'name';
      } else if (line.startsWith('ORG:')) {
        fields.organization = line.replace('ORG:', '').trim();
        currentField = 'organization';
      } else if (line.startsWith('EMAIL:')) {
        fields.email = line.replace('EMAIL:', '').trim();
        currentField = 'email';
      } else if (line.trim() && currentField) {
        fields[currentField as keyof VCardData] += ` ${line.trim()}`;
      }
    });

    fields.name = fields.name.trim();
    fields.organization = fields.organization.trim();
    fields.email = fields.email.trim().replace(' "', '');

    submitForm(
      fields.name.split(';')[1],
      fields.name.split(';')[0],
      fields.email,
      fields.organization
    );
    return fields;
  };

  const handleScan = (result: string | null) => {
    if (result) {
      parseVCard(result);
    }
  };

  const fetchUsers = async () => {
    const _users = await getEventUsers(publicData.event.id);
    setUserList(_users);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <EuiForm>
      <EuiFormRow label="Kunden Kontakt">
        <EuiFormRow label="">
          <EuiCard
            style={{ minWidth: '350px' }}
            title="Scan QR Code"
            description={
              <QrReader
                onResult={(result, error) => {
                  if (result) handleScan(result?.getText() ?? null);
                }}
                constraints={{ facingMode: 'environment' }}
              />
            }
          />
        </EuiFormRow>
      </EuiFormRow>
      <EuiFormRow label="Kunden Kontakt">
        <EuiFormRow label="">
          <EuiComboBox
            options={userList.map((user) => ({
              label: `${user.firstname} ${user.lastname}`,
              value: user,
            }))}
            onChange={onUserChangeChange}
            selectedOptions={selectedUser}
            singleSelection={{ asPlainText: true }}
            isClearable={true}
          />
        </EuiFormRow>
      </EuiFormRow>
    </EuiForm>
  );
};
