import React, { useContext } from 'react';
import { EuiCollapsibleNavGroup, EuiSpacer } from '@elastic/eui';
import { CatalogTree } from '../../components/catalog_tree';
import { useQuestionarie } from '../../_questionnaires';
import { AnketaContext } from '../../components';

export const CurrentQuestionarieNavGroup = (): JSX.Element => {
  const { currentCatalog } = useQuestionarie();
  const ctx = useContext(AnketaContext);

  if (currentCatalog.id !== 'audit') {
    return (
      <EuiCollapsibleNavGroup
        title={ctx.i18nContext.render(currentCatalog.title)}
        isCollapsible={true}
        initialIsOpen={true}
      >
        <EuiSpacer size="s" />
        <CatalogTree />
      </EuiCollapsibleNavGroup>
    );
  } else {
    return <></>;
  }
};
