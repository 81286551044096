import React, { useState } from 'react';
import {
  EuiBreadcrumb,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSections,
  EuiPage,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import { Avatar } from '../components/avatar';
import { AutonubilSvg } from '../_assets';
import { Outlet } from 'react-router';
import { CurrentQuestionarieNavGroup, QuestionarieNavGroup } from './navigation';

export const HomePage = ({ breadcrumbs }: { breadcrumbs: EuiBreadcrumb[] }): JSX.Element => {
  const [navIsOpen, setNavIsOpen] = useState<boolean>(
    JSON.parse(String(localStorage.getItem('ouiCollapsibleNavExample--isDocked'))) || false
  );
  const [navIsDocked, setNavIsDocked] = useState<boolean>(
    JSON.parse(String(localStorage.getItem('ouiCollapsibleNavExample--isDocked'))) || false
  );

  const sections: EuiHeaderSections[] = [
    {
      items: [
        <EuiHeaderLogo
          iconType={AutonubilSvg}
          onClick={() => {
            setNavIsOpen((isOpen) => !isOpen);
            setNavIsDocked(false);
            localStorage.setItem('EuiCollapsibleNavExample--isDocked', JSON.stringify(false));
          }}
          aria-label="Navigate to home page"
        />,
      ],
      borders: 'right',
      breadcrumbs: breadcrumbs,
      breadcrumbProps: {
        'aria-label': 'Navigate Back to the Homepage',
      },
    },
    {
      items: [<Avatar />],
      borders: 'right',
    },
  ];

  return (
    <EuiPage>
      <EuiHeader
        sections={sections}
        style={{
          position: 'fixed',
          top: '0px',
          right: '0px',
          left: navIsDocked ? '350px' : '0px',
          width: '100%',
        }}
      />
      {navIsOpen && (
        <EuiFlyout
          size={350}
          onClose={() => {
            setNavIsOpen(false);
            setNavIsDocked(false);
          }}
          hideCloseButton={navIsDocked}
          ownFocus={!navIsDocked}
          closeButtonPosition="outside"
          side="left"
        >
          <div style={{ padding: 16 }}>
            <EuiTitle>
              <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                  <p>Navigation</p>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    iconType={navIsDocked ? 'returnKey' : 'push'}
                    onClick={() => {
                      if (navIsDocked) {
                        setNavIsOpen(false);
                      }
                      setNavIsDocked(!navIsDocked);
                      localStorage.setItem(
                        'EuiCollapsibleNavExample--isDocked',
                        JSON.stringify(!navIsDocked)
                      );
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiTitle>
            <EuiSpacer />
            <CurrentQuestionarieNavGroup />
            <QuestionarieNavGroup />
          </div>
        </EuiFlyout>
      )}
      <div
        style={{
          position: 'fixed',
          top: '52px',
          right: '0px',
          left: navIsDocked ? '350px' : '0px',
          margin: '15px',
          overflowX: 'hidden',
          overflowY: 'scroll',
          bottom: '0px',
        }}
      >
        <Outlet />
      </div>
    </EuiPage>
  );
};
