import React, { useContext } from 'react';
import {
  EuiFormFieldset,
  EuiPanel,
  EuiTitle,
  EuiSpacer,
  EuiIconTip,
  EuiAccordion,
} from '@elastic/eui';
import { AnketaContext } from '../context_provider';
import { MarkdownText } from '../markdown_text';
import { GroupCardProps } from '../../_types';

export const GroupCard = ({ group }: GroupCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext);

  if (!group.visible) {
    return <div></div>;
  }
  let description;
  if (group.description) {
    description = <MarkdownText text={group.description} />;
  }
  return (
    <>
      <EuiAccordion id={group.id} buttonContent={ctx.i18nContext.render(group.title)}>
        <EuiPanel>
          <EuiFormFieldset key={group.id}>
            {group.help && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <EuiIconTip
                  aria-label={group.id}
                  size="m"
                  type="questionInCircle"
                  color="primary"
                  content={ctx.i18nContext.render(group.help)}
                />
              </div>
            )}
            <EuiTitle size="xxxs">
              <h3>{ctx.i18nContext.render(group.title)}</h3>
            </EuiTitle>
            {description}
            {ctx.childrenForItem(group)}
          </EuiFormFieldset>
        </EuiPanel>
      </EuiAccordion>
      <EuiSpacer />
    </>
  );
};
