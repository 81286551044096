export const isValidCIDR = (cidr: string) => {
  const cidrRegex =
    /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\/(3[0-2]|[12]?[0-9])$/;
  return cidrRegex.test(cidr);
};

export const ipToInt = (ip: string) => {
  return ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0);
};

export const intToIp = (int: number) => {
  return [24, 16, 8, 0].map((shift) => (int >> shift) & 255).join('.');
};

export const calculateHostRange = (cidr: string, hostOffset: number) => {
  const [ip, prefixLength] = cidr.split('/');
  const prefix = parseInt(prefixLength, 10);
  const ipInt = ipToInt(ip);

  const subnetMask = (0xffffffff << (32 - prefix)) >>> 0;
  const networkAddress = ipInt & subnetMask;
  const broadcastAddress = networkAddress | (~subnetMask >>> 0);

  const firstHost = networkAddress + 1;
  const lastHost = broadcastAddress - 1;

  let specificHost = null;
  if (hostOffset >= 0 && hostOffset < (1 << (32 - prefix)) - 2) {
    specificHost = intToIp(firstHost + hostOffset);
  }

  return {
    networkAddress: intToIp(networkAddress),
    firstHost: intToIp(firstHost),
    lastHost: intToIp(lastHost),
    broadcastAddress: intToIp(broadcastAddress),
    subnetMask: intToIp(subnetMask),
    specificHost,
    prefix: prefixLength,
  };
};

export const cidrSubnet = (cidr: string, newBits: number, subnetIndex: number): string => {
  const [base, prefixLengthStr] = cidr.split('/');
  if (!base || !prefixLengthStr) {
    throw new Error('Invalid CIDR format');
  }

  const prefixLength = parseInt(prefixLengthStr, 10);
  const totalBits = 32;

  if (prefixLength < 0 || prefixLength >= totalBits) {
    throw new Error('Invalid prefix length');
  }

  const maxNewBits = totalBits - prefixLength;
  if (newBits > maxNewBits) {
    throw new Error(`Cannot add ${newBits} new bits. Max allowed is ${maxNewBits}`);
  }

  const subnetsCount = 1 << newBits;
  if (subnetIndex < 0 || subnetIndex >= subnetsCount) {
    throw new Error(`Subnet index out of range. Valid range: 0-${subnetsCount - 1}`);
  }

  const baseIp = ipToInt(base);
  const newPrefixLength = prefixLength + newBits;

  const newSubnetSize = 1 << (totalBits - newPrefixLength);

  const subnetStartIp = baseIp + subnetIndex * newSubnetSize;

  return `${intToIp(subnetStartIp)}/${newPrefixLength}`;
};

export const generateMacAddress = (offset: number, prefix: string): string => {
  return `${prefix}:${(offset & 0xff).toString(16).padStart(2, '0')}`;
};
