import { QuestionarieCatalogs } from '../../_types/questionnaires';
import Mustache from 'mustache';
import { IAxios, IReturn } from '../../_types';
import { parseOpsanioCatalog } from './opsanio';

export const removeNumbersFromKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeNumbersFromKeys(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {};

    Object.keys(obj).forEach((key) => {
      const newKey = key.replace(/[0-9]/g, '').replaceAll('a_', '').replaceAll('_', '');
      newObj[newKey] = removeNumbersFromKeys(obj[key]);
    });

    return newObj;
  } else {
    return obj;
  }
};

const downloadFile = (content: string, fileName: string) => {
  const blob = new Blob([content], { type: 'text/markdown' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

const opsanioDocumentation = (
  catalog: any,
  Get: (params: IAxios) => Promise<IReturn>,
  Post: (params: IAxios) => Promise<IReturn>,
  token: string
) => {
  const values = parseOpsanioCatalog(catalog);

  let template = '';
  let opsanioNetworksTemplate = '';
  Promise.all([
    Get({
      path: `v2/questionaries/template/opsanio/opsanio/md`,
      token: token,
    }),
    Get({
      path: `v2/questionaries/template/opsanio/network/drawio`,
      token: token,
    }),
    Get({
      path: `v2/questionaries/template/opsanio/agent-init/tpl`,
      token: token,
    }),
    Get({
      path: `v2/opsanio/vault`,
      token: token,
    }),
  ])
    .then(([templateData, opsanioNetworksTemplateData, setupTemplate, vaultData]) => {
      template = templateData.data;
      opsanioNetworksTemplate = opsanioNetworksTemplateData.data;

      const networkIo = Mustache.render(opsanioNetworksTemplate, values);
      catalog.images = {
        networkIo: networkIo,
      };

      const agnetsTpl: any = [];
      const documantation = Mustache.render(template, values);
      values.agents.forEach((agent) => {
        const newValues = {
          ...values,
          agents: agent,
          allAgentsExanio: values.agents.map((agent) => ({
            ip: agent.networks.exanio,
          })),
        };
        console.log(newValues);
        const tpl = Mustache.render(setupTemplate.data, newValues);
        // downloadFile(tpl, `setup-${agent.hostname}.sh`);
        agnetsTpl.push({
          name: `setup-${agent.hostname}.sh`,
          data: tpl,
        });
      });
      Post({
        path: `v2/opsanio/documentation/${values.commons.owner}/${values.commons.project}/${values.commons.stage}`,
        token: token,
        value: {
          readme: documantation,
          network: networkIo,
          agents: agnetsTpl,
        },
      });
      // downloadFile(documantation, `${values.commons.opsanioFullStage}.md`);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const documentation = (
  data: any,
  catalog: string,
  Get: (params: IAxios) => Promise<IReturn>,
  Post: (params: IAxios) => Promise<IReturn>,
  token: string
) => {
  if (catalog === QuestionarieCatalogs.Opsanio) {
    return opsanioDocumentation(data, Get, Post, token);
  }
};
