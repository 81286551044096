import { EuiBadge, EuiBasicTableColumn, EuiText, EuiToolTip } from '@elastic/eui';
import moment from 'moment';
import { NavigateFunction } from 'react-router';
import { RouteQuestionarie, RouterAction } from '../../_functions/common';
import { QuestionarieActions, QuestionarieParams } from '../../_types/questionnaires';

export const columnsNewsletter = (
  navigate?: NavigateFunction
): EuiBasicTableColumn<QuestionarieParams>[] => {
  const columns: EuiBasicTableColumn<QuestionarieParams>[] = [
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      truncateText: true,
      render: (name: string) => {
        return (
          <EuiText>
            <p>{name}</p>
          </EuiText>
        );
      },
    },
    {
      field: 'catalog',
      name: 'Catalog',
      sortable: true,
      truncateText: true,
      render: (catalog: string) => {
        return (
          <EuiBadge>
            <EuiText>{catalog}</EuiText>
          </EuiBadge>
        );
      },
    },
    {
      field: 'last_updated',
      name: 'Letzte änderung',
      sortable: true,
      truncateText: true,
      render: (last_updated: string, record: QuestionarieParams) => {
        return (
          <EuiToolTip content={moment(last_updated).format('lll')}>
            <EuiText style={{ cursor: 'pointer' }}>
              <p>{record.updated_by}</p>
            </EuiText>
          </EuiToolTip>
        );
      },
    },
    {
      field: 'action',
      name: '',
      actions: [
        {
          name: 'Pre-View',
          description: 'Event Details',
          type: 'icon',
          icon: 'eye',
          onClick: (item: QuestionarieParams) => {
            if (navigate) {
              navigate(`/${RouteQuestionarie}/${item.catalog}/${item.name}`);
            }
          },
        },
        {
          name: 'Löschen',
          description: 'Element löschen',
          type: 'icon',
          icon: 'trash',
          onClick: (item: QuestionarieParams) => {
            if (navigate) {
              navigate(
                `/${RouteQuestionarie}/${item.catalog}/${item.name}?${RouterAction}=${QuestionarieActions.Delete}`
              );
            }
          },
        },
      ],
      width: '100px',
    },
  ];
  return columns;
};
