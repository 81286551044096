import React, { type ReactNode, createContext, useState, useEffect, useContext } from 'react';
import {
  type Action,
  ItemObject,
  type Item,
  ExecutionContext,
  I18nContext,
  ItemType,
  type Hierarchical,
  type Section,
  type Row,
  type Matrix,
  type Group,
  type Text,
  DefaultCatalog,
  isQuestion,
} from 'anketa-core';
import { GroupCard } from '../group_card';
import { RowCard } from '../row_card';
import { QuestionCard } from '../question_card';
import { MatrixCard } from '../matrix_card';
import { SectionCard } from '../section_card';
import { TextCard } from '../text_card';
import { ActionButton } from '../action_button';
import { EuiContext } from '@elastic/eui';

export const checkLocalStorageAccess = (): boolean => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const EvaluationContext = createContext({
  evaluationId: 0,
  nextEvaluationId: (): any => {},
  focusItem: '',
  setFocus: (itemPath: string): any => {},
});

export interface AnketaContextProviderProps {
  catalog: Item | undefined;
  locale: string;
  children: ReactNode;
}

export type ActionObserver = (action: Action) => void;
// export type FactObserver = (fact: Fact) => void

export class AnketaEuiContext extends ExecutionContext {
  i18n: any;
  private actionObservers: ActionObserver[] = [];

  constructor(catalog?: Item, locale?: string) {
    super(
      catalog && checkLocalStorageAccess()
        ? catalog.factsFromObject(localStorage.getItem('anketa-' + catalog.id + '-facts'))
        : catalog,
      new I18nContext(locale)
    );
    this.locale = locale ?? I18nContext.Global.locale.baseName;
    this.reevaluate();
  }

  public attachActions(observer: ActionObserver): void {
    this.actionObservers.push(observer);
    console.debug('Attach Action', observer);
  }

  public detachActions(observerToRemove: ActionObserver): void {
    this.actionObservers = this.actionObservers.filter((observer) => observerToRemove !== observer);
  }

  private notifyAction(action: Action): void {
    this.actionObservers.forEach((observer) => {
      observer(action);
    });
  }

  public reevaluate(): void {
    super.evaluate();
  }

  public onAction(action: Action): void {
    console.debug('Action triggered', action.id);
    this.notifyAction(action);
  }

  public saveToBrowser(): void {
    if (this.catalog) {
      const f = this.catalog.factsToObject(this.catalog);
      checkLocalStorageAccess() &&
        localStorage.setItem('anketa-' + this.catalog.id + '-facts', JSON.stringify(f));
    }
  }

  public loadFromBrowser(): void {
    if (this.catalog) {
      if (checkLocalStorageAccess()) {
        const storedJSON = localStorage.getItem('anketa-' + this.catalog.id + '-facts');
        if (storedJSON) {
          const stored = JSON.parse(storedJSON);
          this.catalog.factsFromObject(stored);
        }
      }
    }
  }

  public get locale(): string {
    return this.i18nContext.locale.baseName;
  }

  public set locale(val: string) {
    this.i18nContext.locale = new Intl.Locale(val);
    this.i18n = {
      // mapping: language_mappings[language as keyof LanguageMapping],
      formatNumber: (value: number) => this.i18nContext.formatNumber(value),
    };
  }

  public componentForItem(item: Item): JSX.Element {
    return (
      <div>
        Unhandled item type: <pre>{JSON.stringify(item.save, null, 2)}</pre>
      </div>
    );
  }

  public childrenForItem(item: Item, rootPath?: string): JSX.Element[] {
    const result = new Array<JSX.Element>();
    item.children.forEach((child: Hierarchical) => {
      if (child instanceof ItemObject) {
        if (child.type === ItemType.Row) {
          result.push(<RowCard key={child.path} row={child as Row}></RowCard>);
        }
        if (child.type === ItemType.Matrix) {
          result.push(
            <MatrixCard key={child.path} matrix={child as Matrix} stripes={true}></MatrixCard>
          );
        }
        if (child.type === ItemType.Group) {
          result.push(<GroupCard key={child.path} group={child as Group}></GroupCard>);
        }
        if (child.type === ItemType.Text) {
          result.push(<TextCard key={child.path} text={child as Text}></TextCard>);
        }
        if (child.type === ItemType.Section) {
          const inPath = !rootPath || child.inPath(rootPath ?? 'x');
          if (rootPath && rootPath !== '' && !inPath) {
            return;
          }
          result.push(<SectionCard key={child.path} section={child as Section}></SectionCard>);
        }
        if (child.type === ItemType.Action) {
          result.push(<ActionButton key={child.path} action={child as Action}></ActionButton>);
        }
        if (isQuestion(child)) {
          result.push(<QuestionCard key={child.path} question={child}></QuestionCard>);
        }
      }
    });
    return result;
  }
}

export const AnketaContext = createContext<AnketaEuiContext>(
  new AnketaEuiContext(DefaultCatalog.instance, 'en')
);

export const AnketaContextProvider = ({
  catalog,
  locale,
  children,
}: AnketaContextProviderProps): JSX.Element => {
  const nextEvaluationId = (): any => {
    setEvaluationId((prevEvaluation: any): any => ({
      evaluationId: (prevEvaluation.evaluationId as number) + 1,
      nextEvaluationId,
    }));
  };
  const setFocus = (itemPath: string): any => {
    setFocusItem((prevPath: any): any => {
      return {
        focusItem: itemPath,
        setFocus,
      };
    });
  };

  const [ctx, setCtx] = useState<AnketaEuiContext | undefined>();
  const [evaluationId, setEvaluationId] = useState({ evaluationId: 0, nextEvaluationId });
  const [focusItem, setFocusItem] = useState({ focusItem: ctx ? ctx.catalog.path : '/', setFocus });

  useEffect(() => {
    if (!ctx) {
      setCtx(new AnketaEuiContext(catalog, locale));
    } else {
      if (locale.toLowerCase() !== ctx.i18nContext.locale.baseName.toLowerCase()) {
        const newCtx = new AnketaEuiContext(catalog, locale);
        newCtx.catalog = ctx.catalog;
        setCtx(newCtx);
      } else if (catalog?.id !== ctx.catalog.id) {
        setCtx(new AnketaEuiContext(catalog, locale));
      }
    }
  }, [ctx, catalog, locale]);

  if (!ctx) {
    return <></>;
  }
  return (
    <EvaluationContext.Provider
      value={{
        evaluationId: evaluationId.evaluationId,
        nextEvaluationId: evaluationId.nextEvaluationId,
        focusItem: focusItem.focusItem,
        setFocus: focusItem.setFocus,
      }}
    >
      <AnketaContext.Provider value={ctx}>
        <EuiContext i18n={ctx.i18n}>{children}</EuiContext>
      </AnketaContext.Provider>
    </EvaluationContext.Provider>
  );
};

export const useAnketa = (): AnketaEuiContext => {
  const context = useContext(AnketaContext);
  if (context === undefined) {
    throw new Error('useAnketa must be used within a AnketaContextProvider ');
  }
  return context as AnketaEuiContext;
};
