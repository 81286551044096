import React, { useEffect, useMemo } from 'react';
import { OpsTable } from '../../components/tabel';
import { columnsNewsletter } from './columns';
import { Outlet, useNavigate, useParams } from 'react-router';
import { AuthorizeItem, useKeycloak } from '../../_functions';
import { RouterAction } from '../../_functions/common';
import { useQuestionarie } from '../context';
import { useSearchParams } from 'react-router-dom';
import { CreateQuestionnaires, DeleteQuestionnaires } from '../modal';
import {
  KeycloakRolePrefix,
  QuestionarieActions,
  QuestionarieParams,
} from '../../_types/questionnaires';

export const QuestionarieTable = (): JSX.Element => {
  const { anketaRoles, keycloak } = useKeycloak();
  const { setSelectedQuestionarie, currentCatalog, questionaries } = useQuestionarie();

  const navigate = useNavigate();
  const { questionarie, catalog } = useParams();
  const [searchParams] = useSearchParams();

  const columns = columnsNewsletter(navigate);

  const filteredQuestionaries: QuestionarieParams[] = useMemo(() => {
    let filtered: QuestionarieParams[] = questionaries;

    if (catalog && catalog !== '') {
      filtered = filtered.filter((q: QuestionarieParams) => q.catalog === catalog);
    }

    if (questionarie && questionarie !== '') {
      filtered = filtered.filter((q: QuestionarieParams) => q.name === questionarie);
    }
    return filtered;
  }, [questionaries, catalog, questionarie]);

  const neededRole: any = useMemo(() => {
    let role: any = '';

    if (catalog && catalog !== '') {
      role = `${KeycloakRolePrefix}-${catalog}`;
    }

    if (questionarie && questionarie !== '') {
      role = `${KeycloakRolePrefix}-${catalog}-${questionarie}`;
    }

    return role;
  }, [anketaRoles, catalog, questionarie]);

  useEffect(() => {
    if (filteredQuestionaries.length === 1) {
      setSelectedQuestionarie(filteredQuestionaries[0].catalog, filteredQuestionaries[0].name);
    }
  }, [filteredQuestionaries]);

  const modalMode: string | null = useMemo(() => {
    return searchParams.get(RouterAction);
  }, [searchParams]);

  return (
    <AuthorizeItem key={`questionaries:tabels`} ctx={keycloak} roleName={neededRole}>
      {currentCatalog.id === 'audit' && (
        <OpsTable
          responsive={true}
          items={filteredQuestionaries}
          compressed={true}
          columns={columns}
          sorting={true}
          opsfiltercolumns={['catalog', 'updated_by']}
          opssearch
          pagination={{
            pageSizeOptions: [10, 25, 50, 100, 250],
            initialPageSize: 10,
          }}
        />
      )}
      {modalMode === QuestionarieActions.Create && <CreateQuestionnaires />}
      {modalMode === QuestionarieActions.Delete && <DeleteQuestionnaires />}
      <Outlet />
    </AuthorizeItem>
  );
};
