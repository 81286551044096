import React from 'react';
import { EuiConfirmModal } from '@elastic/eui';
import { useNavigate, useParams } from 'react-router';
import { RouteQuestionarie } from '../../_functions/common';
import { useQuestionarie } from '../context';

export const DeleteQuestionnaires = (): JSX.Element => {
  const { deleteQ } = useQuestionarie();

  const { catalog, questionarie } = useParams();
  const navigate = useNavigate();

  const closeAction = (): void => {
    navigate(`/${RouteQuestionarie}/${catalog}`);
  };
  return (
    <EuiConfirmModal
      title="Do this destructive thing"
      onCancel={closeAction}
      onConfirm={() => {
        if (catalog && questionarie) {
          console.log({ catalog, questionarie });
          deleteQ(catalog, questionarie);
        }
        closeAction();
      }}
      cancelButtonText="Abbrechen"
      confirmButtonText="Löschen"
      buttonColor="danger"
      defaultFocusedButton="confirm"
    >
      <p>
        Bist du dir sicher das du den Fragebogen {questionarie}({catalog}) löschen willst?
      </p>
      <p>Are you sure you want to do this?</p>
    </EuiConfirmModal>
  );
};
