import { CatalogObject, Group, OpenQuestion, Section, Translation } from 'anketa-core';
import { KeycloakInstance } from 'keycloak-js';
import { generateActions } from './actions';
import { AuthorizeComponent } from '../../../_functions';

export class OnboardingCatalog extends CatalogObject {
  private static _instance: OnboardingCatalog;

  public static get instance(): OnboardingCatalog {
    if (!OnboardingCatalog._instance) {
      throw new Error(
        'OnboardingCatalog instance has not been created. Use createInstance(name) method first.'
      );
    }
    return OnboardingCatalog._instance;
  }

  // user: userData,
  // groups,
  // licenses,

  public static createInstance(
    values: any,
    changeListener: () => void,
    authCtx: KeycloakInstance
  ): OnboardingCatalog {
    if (!OnboardingCatalog._instance) {
      OnboardingCatalog._instance = new OnboardingCatalog(
        values.user.userPrincipalName,
        changeListener,
        authCtx
      );
    }
    return OnboardingCatalog._instance;
  }

  constructor(values: any, changeListener: () => void, authCtx: KeycloakInstance) {
    const id: string = values.user.id.split('-')[0];
    super(id, [
      new Translation('en', values.user.userPrincipalName),
      new Translation('de', values.user.userPrincipalName),
    ]);

    const supportAdmin: boolean = AuthorizeComponent(authCtx, 'anketa-support');

    const userSection = new Section('user', [new Translation('en', ''), new Translation('de', '')]);
    const nameGroup = new Group('name', [new Translation('en', ''), new Translation('de', '')]);

    const displayName = new OpenQuestion('display', [
      new Translation('en', 'Display Name'),
      new Translation('de', 'Anzeigename'),
    ]);
    displayName.setFactValue(values.user.displayName);
    nameGroup.addChild(displayName);

    const givenName = new OpenQuestion('given', [
      new Translation('en', 'Given Name'),
      new Translation('de', 'Vorname'),
    ]);
    givenName.setFactValue(values.user.givenName);
    nameGroup.addChild(givenName);

    const surname = new OpenQuestion('surname', [
      new Translation('en', 'Surname'),
      new Translation('de', 'Nachname'),
    ]);
    givenName.setFactValue(values.user.surname);
    nameGroup.addChild(surname);

    const userPrincipalName = new OpenQuestion('principal', [
      new Translation('en', 'User Principal Name'),
      new Translation('de', 'User Principal Name'),
    ]);
    givenName.setFactValue(values.user.userPrincipalName);
    nameGroup.addChild(userPrincipalName);

    userSection.addChild(nameGroup);

    this.addChild(userSection);
    this.addChild(generateActions(`onboarding_${id}`, changeListener, authCtx));
  }
}
