import React, { useContext, useEffect, useState } from 'react';
import { EuiEmptyPrompt } from '@elastic/eui';
import { AnketaContext, EvaluationContext } from '../context_provider';
import { Item, Translation } from 'anketa-core';
import { CatalogTreeSmall } from '../catalog_tree';

export const CatalogCard = (props: any): JSX.Element => {
  const ctx = useContext(AnketaContext);
  const evalCtx = useContext(EvaluationContext);
  const [item, setItem] = useState<JSX.Element>(<></>);

  useEffect(() => {
    const pieces = evalCtx.focusItem.split('.');
    const last = pieces[pieces.length - 1];
    const renderChild =
      ctx.catalog.children.get(last) || Array.from(ctx.catalog.children.values())[0];
    console.log(Array.from(ctx.catalog.factsToObject()));
    if (renderChild !== undefined) {
      setItem(<>{ctx.childrenForItem(renderChild as Item)}</>);
    } else {
      const title = [
        new Translation('en', `Please choose an Section.`),
        new Translation('de', `Bitte wählen Sie eine Sektion.`),
      ];
      setItem(
        <EuiEmptyPrompt
          iconType="editorStrike"
          title={<h2>{ctx.i18nContext.render(title)}</h2>}
          body={<CatalogTreeSmall showHidden />}
        />
      );
    }
  }, [ctx, evalCtx]);

  return item;
};
