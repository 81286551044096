import React, { useEffect, useState } from 'react';
import { Route, useLocation, useNavigate } from 'react-router';
import { Routes } from 'react-router';
import { HomePage } from '..';
import { QuestinaryBody, QuestionarieTable, useQuestionarie } from '../../_questionnaires';
import { EuiBreadcrumb } from '@elastic/eui';
import { RouteQuestionarie } from '../../_functions/common';
import { useKeycloak } from '../../_functions';
import { isValidCatalog } from '../../_types/questionnaires';

export const Navigation = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { anketaRoles } = useKeycloak();

  const [navigationBreadcrumbs, setNavigationBreadcrumbs] = useState<EuiBreadcrumb[]>([]);
  const { currentCatalog } = useQuestionarie();

  const generateBreadcrumbs = (path: string): EuiBreadcrumb[] => {
    const pathItems = path.split('/').filter((item) => item !== '');
    const breadcrumbs: EuiBreadcrumb[] = pathItems.map((item, idx) => {
      const cumulativePath = `/${pathItems.slice(0, idx + 1).join('/')}`;
      return {
        text: item,
        onClick: () => {
          navigate(cumulativePath);
        },
      };
    });

    return breadcrumbs;
  };

  useEffect(() => {
    setNavigationBreadcrumbs(generateBreadcrumbs(location.pathname));
  }, [location]);

  useEffect(() => {
    if (anketaRoles.length === 1) {
      const [prefix, catlog, questionarie] = anketaRoles[0].split('-');
      const _isValidCatalog = isValidCatalog(catlog);
      if (_isValidCatalog) {
        navigate(`/${RouteQuestionarie}/${catlog}/${questionarie}`);
      }
    }
  }, [anketaRoles]);

  return (
    <Routes>
      <Route path="/" element={<HomePage breadcrumbs={navigationBreadcrumbs} />}>
        <Route path={RouteQuestionarie} element={<QuestionarieTable />}>
          <Route path=":catalog">
            <Route
              path={`:questionarie`}
              element={currentCatalog.id !== 'audit' && <QuestinaryBody />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
