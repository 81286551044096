import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import {
  BasicUser,
  Campaign,
  Companie,
  Events,
  Mail,
  Newsletter,
  NewsletterContextType,
  NewsletterDetails,
  Partners,
  Presentation,
  PresentationPublic,
  UserDetail,
  UserEventDetails,
  UserMail,
} from '../../_types';
import { useApi } from '../api';
import { useKeycloak } from '../auth';
import { useToast } from '../toast';
import { deduplicateArray } from '../validators';
import { useLocation } from 'react-router';

const NewsletterContext = createContext<NewsletterContextType | undefined>(undefined);

export const NewletterProvider = ({ children }: { children: ReactNode }) => {
  const { Get, Delete, Post, Put } = useApi();
  const { keycloak } = useKeycloak();
  const { addToast } = useToast();
  const location = useLocation();

  const [companies, setCompanies] = useState<Companie[]>([]);
  const [events, setEvents] = useState<Events[]>([]);
  const [partners, setPartners] = useState<Partners[]>([]);
  const [campaign, setCampaign] = useState<Campaign[]>([]);
  const [customers, setCustomers] = useState<UserDetail[]>([]);
  const [newsletters, setNewsletters] = useState<NewsletterDetails[]>([]);
  const [mailUsers, setMailUsers] = useState<UserMail[]>([]);
  const [scannedMailbox, setScannedMailbox] = useState<string>('');
  const [presentations, setPresentations] = useState<Presentation[]>([]);
  const [trigger, setTrigger] = useState<number>(0);

  const getCompanies = async () => {
    try {
      const cmpns = await Get({ path: 'companies', token: keycloak?.token });
      setCompanies(deduplicateArray(cmpns.data, 'id'));
    } catch (err) {
      console.error('There was an Error fetching companies!', err);
      setCompanies([]);
    }
  };

  const getCampaign = async () => {
    try {
      const cmpgn = await Get({ path: 'campaigns', token: keycloak?.token });
      setCampaign(deduplicateArray(cmpgn.data, 'id'));
    } catch (err) {
      console.error('There was an Error fetching campaigns!', err);
      setCampaign([]);
    }
  };

  const getCustomers = async () => {
    try {
      const cstmrs = await Get({ path: 'users', token: keycloak?.token });
      setCustomers(deduplicateArray(cstmrs.data, 'id'));
    } catch (err) {
      console.error('There was an Error fetching customers!', err);
      setCustomers([]);
    }
  };

  const getPartnerUsers = async (id: string): Promise<BasicUser[]> => {
    try {
      const cmpns = await Get({
        path: `companies/partner/${id}/users`,
        token: keycloak?.token,
      });
      return deduplicateArray(cmpns.data, 'id');
    } catch (err) {
      console.error(`Error fetching users for partner ID ${id}:`, err);
      return [];
    }
  };

  const getPartnerEvents = async (id: string): Promise<Companie[]> => {
    try {
      const cmpns = await Get({
        path: `companies/partner/${id}/events`,
        token: keycloak?.token,
      });
      return deduplicateArray(cmpns.data, 'id');
    } catch (err) {
      console.error(`Error fetching users for partner ID ${id}:`, err);
      return [];
    }
  };

  const getEventPartners = async (id: string): Promise<Companie[]> => {
    try {
      const cmpns = await Get({
        path: `companies/events/${id}/partner`,
        token: keycloak?.token,
      });
      return deduplicateArray(cmpns.data, 'id');
    } catch (err) {
      console.error(`Error fetching users for partner ID ${id}:`, err);
      return [];
    }
  };

  const getUserEventDetails = async (
    event: string,
    user: string
  ): Promise<UserEventDetails | null> => {
    try {
      const cmpns = await Get({
        path: `companies/events/${event}/user/${user}`,
        token: keycloak?.token,
      });
      return cmpns.data as UserEventDetails;
    } catch (err) {
      console.error(`Error fetching users with the ID ${user}:`, err);
      return null;
    }
  };

  const getEventUser = async (id: string): Promise<BasicUser[]> => {
    try {
      const cmpns = await Get({
        path: `companies/events/${id}/user`,
        token: keycloak?.token,
      });
      return deduplicateArray(cmpns.data, 'id');
    } catch (err) {
      console.error(`Error fetching users for partner ID ${id}:`, err);
      return [];
    }
  };

  const getEventPartner = (eventId: string) => {
    return partners.filter((p) => {
      return p.events.some((e) => e.id === eventId);
    });
  };

  const getEvents = async (): Promise<void> => {
    try {
      const cmpns = await Get({
        path: `companies/events`,
        token: keycloak?.token,
      });

      const events = deduplicateArray(cmpns.data, 'id');
      const eventsData = await Promise.all(
        events.map(async (e) => {
          try {
            const guests = await getEventUser(e.id);
            const partners = await getEventPartners(e.id);
            const presentations = await getEventPresentations(e.id);
            return {
              event: { id: e.id, name: e.name },
              guests,
              partner: partners,
              presentations: presentations,
            } as Events;
          } catch (innerError) {
            console.error(`Error fetching details for event ID ${e.id}:`, innerError);
            return null;
          }
        })
      );

      const plainEvents: Events[] = eventsData.filter((event): event is Events => event !== null);

      setEvents(plainEvents);
    } catch (err) {
      console.error(`Error fetching events:`, err);
      setEvents([]);
    }
  };
  const createEvents = async (name: string): Promise<void> => {
    Post({
      path: `companies/events`,
      token: keycloak?.token,
      value: { name },
    })
      .then((response) => {
        if (response.status === 500) {
          addToast({
            title: 'Fehler beim erstellen des neuen Events!',
            text: <p>{response.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        } else {
          addToast({
            title: 'Event erstellt!',
            text: <p>Das Event {name} wurde erfolgreich erstellt!</p>,
            color: 'success',
            iconType: 'check',
          });
          setTrigger((prev) => prev + 1);
        }
      })
      .catch((err) => {
        addToast({
          title: 'Fehler beim erstellen des neuen Events!',
          text: <p>{err}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };

  const getEventUsers = async (eventId: string) => {
    try {
      const cmpns = await Get({
        path: `public/event/${eventId}/user/list`,
      });

      return cmpns.data;
    } catch (err) {
      console.error('There was an error fetching partners!', err);
      return [];
    }
  };

  const getPartners = async () => {
    try {
      const cmpns = await Get({
        path: 'companies/partner',
        token: keycloak?.token,
      });
      const partnerArray = deduplicateArray(cmpns.data, 'id');

      const partnersWithEmployees = await Promise.all(
        partnerArray.map(async (p) => {
          const comp: Companie = {
            id: p.id,
            name: p.name,
          };

          const employees = await getPartnerUsers(p.id);
          const events = await getPartnerEvents(p.id);

          return {
            companie: comp,
            employees,
            events,
          };
        })
      );

      setPartners(partnersWithEmployees);
    } catch (err) {
      console.error('There was an error fetching partners!', err);
      setPartners([]);
    }
  };

  const getPresentations = async () => {
    try {
      const cmpns = await Get({
        path: 'companies/events/presentations',
        token: keycloak?.token,
      });
      setPresentations(cmpns.data);
    } catch (err) {
      console.error('There was an error fetching partners!', err);
      setPresentations([]);
    }
  };

  const getPublicPresentations = async (eventId: string): Promise<PresentationPublic[]> => {
    try {
      const cmpns = await Get({
        path: `public/events/${eventId}/presentations`,
      });
      return cmpns.data;
    } catch (err) {
      console.error('There was an error fetching partners!', err);
      return [];
    }
  };

  const getEventPresentations = async (id: string): Promise<Presentation[]> => {
    try {
      const eventPresentations = await Get({
        path: `companies/events/${id}/presentations`,
        token: keycloak?.token,
      });
      if (Array.isArray(eventPresentations.data)) {
        return eventPresentations.data;
      } else {
        return [];
      }
    } catch (err) {
      console.error('There was an error fetching partners!', err);
      return [];
    }
  };
  const getNewsletters = async () => {
    try {
      const nwsltrs = await Get({ path: 'newsletter', token: keycloak?.token });
      if (nwsltrs.status === 500) {
        console.error('There was an Error fetching newsletters!');
        setNewsletters([]);
      } else {
        setNewsletters(deduplicateArray(nwsltrs.data, 'id'));
      }
    } catch (err) {
      console.error('There was an Error fetching newsletters!', err);
      setNewsletters([]);
    }
  };
  const getMailUsers = async () => {
    try {
      const mlsrs = await Get({ path: 'mail/users', token: keycloak?.token });
      setMailUsers(deduplicateArray(mlsrs.data, 'id'));
    } catch (err) {
      console.error('There was an Error fetching mailUsers!', err);
      setMailUsers([]);
    }
  };
  const getScannedMailbox = async () => {
    try {
      const mailbox = await Get({ path: 'mail/forward', token: keycloak?.token });
      setScannedMailbox(mailbox.data);
    } catch (err) {
      console.error('There was an Error fetching mailbox!', err);
      setScannedMailbox('');
    }
  };

  const getPreview = async (newsletter: string) => {
    try {
      const wantedNewsletter = newsletters.filter((news: NewsletterDetails) => {
        return news.id === newsletter;
      })[0];
      const senderName = mailUsers.filter((user: UserMail) => {
        return user.email === wantedNewsletter.sender;
      })[0].displayName;
      const values: { mail: Mail; sendFrom: string } = {
        mail: {
          userId: `${keycloak?.profile?.id}`,
          name: `${keycloak?.profile?.firstName} ${keycloak?.profile?.lastName}`,
          from: wantedNewsletter.from_email,
          text: wantedNewsletter.text,
          newsletterId: wantedNewsletter.id,
          newsletterName: wantedNewsletter.name,
        },
        sendFrom: senderName,
      };
      const preview = await Post({
        path: 'newsletter/preview',
        token: keycloak?.token,
        value: values,
      });
      return preview.data;
    } catch (err) {
      console.error('There was an Error fetching preview!', err);
      return;
    }
  };

  const deleteNewsletter = async (id: string) => {
    await Delete({ path: `newsletter/${id}`, token: keycloak?.token })
      .then((response) => {
        if (response.status === 500) {
          addToast({
            title: 'Fehler beim löschen des Newsletters!',
            text: <p>{response.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        } else {
          setTrigger((prev) => prev + 1);
          addToast({
            title: 'Newsletter gelöscht!',
            text: <p>Der Newsletter wurde erfolgreich gelöscht!</p>,
            color: 'success',
            iconType: 'check',
          });
        }
      })
      .catch((err) => {
        addToast({
          title: 'Fehler beim löschen des Newsletters!',
          text: <p>{err}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };

  const deleteUser = async (id: string) => {
    await Delete({ path: `users/${id}`, token: keycloak?.token })
      .then((response) => {
        if (response.status === 500) {
          addToast({
            title: 'Fehler beim erstellen des neuen Nutzers!',
            text: <p>{response.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        } else {
          setTrigger((prev) => prev + 1);
          addToast({
            title: 'Nutzer erstellt!',
            text: <p>Der Nutzer wurde erfolgreich gelöscht!</p>,
            color: 'success',
            iconType: 'check',
          });
        }
      })
      .catch((err) => {
        addToast({
          title: 'Fehler beim erstellen des neuen Nutzers!',
          text: <p>{err}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };

  const deletePartner = async (id: string) => {
    await Delete({ path: `companies/partner/${id}`, token: keycloak?.token })
      .then((response) => {
        if (response.status === 500) {
          addToast({
            title: 'Fehler beim Löschem des Partneres!',
            text: <p>{response.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        } else {
          setTrigger((prev) => prev + 1);
          addToast({
            title: 'Partner gelöscht!',
            text: <p>Der Partner erfolgreich gelöscht!</p>,
            color: 'success',
            iconType: 'check',
          });
        }
      })
      .catch((err) => {
        addToast({
          title: 'Fehler beim Löschem des Partneres!',
          text: <p>{err}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };

  const deleteEvent = async (id: string) => {
    await Delete({ path: `companies/events/${id}`, token: keycloak?.token })
      .then((response) => {
        if (response.status === 500) {
          addToast({
            title: 'Fehler beim Löschem des Events!',
            text: <p>{response.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        } else {
          setTrigger((prev) => prev + 1);
          addToast({
            title: 'Event gelöscht!',
            text: <p>Das Event wurde erfolgreich gelöscht!</p>,
            color: 'success',
            iconType: 'check',
          });
        }
      })
      .catch((err) => {
        addToast({
          title: 'Fehler beim Löschem des Events!',
          text: <p>{err}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };

  const createUser = async (
    email: string,
    vorname: string,
    nachname: string,
    title: string,
    unternhmen: string,
    campaign: string
  ) => {
    console.warn(email, vorname, nachname, title, unternhmen, campaign);

    Post({
      path: `users/create`,
      value: {
        email: email,
        firstname: vorname,
        lastName: nachname,
        title: title,
        company: unternhmen,
        campaign: campaign,
      },
      token: keycloak?.token,
    })
      .then((response) => {
        if (response.status === 500) {
          addToast({
            title: 'Fehler beim erstellen des neuen Nutzers!',
            text: <p>{response.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        } else {
          addToast({
            title: 'Nutzer erstellt!',
            text: <p>Der Nutzer mit der {email} wurde erfolgreich erstellt!</p>,
            color: 'success',
            iconType: 'check',
          });
          setTrigger((prev) => prev + 1);
        }
      })
      .catch((err) => {
        addToast({
          title: 'Fehler beim erstellen des neuen Nutzers!',
          text: <p>{err}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };

  const createPartnerUser = async (
    fName: string,
    lName: string,
    email: string,
    company: string
  ) => {
    Post({
      path: `companies/partner/${company}/users`,
      value: {
        email: email,
        firstname: fName,
        lastName: lName,
      },
      token: keycloak?.token,
    })
      .then((response) => {
        if (response.status === 500) {
          addToast({
            title: 'Fehler beim erstellen des neuen Nutzers!',
            text: <p>{response.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        } else {
          addToast({
            title: 'Nutzer erstellt!',
            text: <p>Der Nutzer mit der {email} wurde erfolgreich erstellt!</p>,
            color: 'success',
            iconType: 'check',
          });
          setTrigger((prev) => prev + 1);
        }
      })
      .catch((err) => {
        addToast({
          title: 'Fehler beim erstellen des neuen Nutzers!',
          text: <p>{err}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };
  const assignPartnerToEvent = async (eventId: string, partnerId: string) => {
    try {
      const response = await Post({
        path: `companies/events/${eventId}/partner/${partnerId}`,
        token: keycloak?.token,
      });

      if (response.status === 200) {
        addToast({
          title: 'Partner zu Event hinzugefügt!',
          text: <p>Der Partner wurde erfolgreich dem Event zugewiesen.</p>,
          color: 'success',
          iconType: 'check',
        });
        setTrigger((prev) => prev + 1);
      } else {
        addToast({
          title: 'Fehler bei der Zuweisung des Partners!',
          text: <p>{response.data}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      }
    } catch (err) {
      addToast({
        title: 'Fehler bei der Zuweisung des Partners!',
        text: <p>{err || err}</p>,
        color: 'danger',
        iconType: 'cross',
      });
    }
  };
  const createEventPresentation = async (
    eventId: string,
    title: string,
    time: string,
    partnerId: string
  ) => {
    try {
      const response = await Post({
        path: `companies/events/${eventId}/presentation`,
        token: keycloak?.token,
        value: { title, time, partnerId },
      });

      if (response.status === 200) {
        addToast({
          title: 'Erfolgreich erstellt!',
          text: <p>Die Presentzation wurde erstellt</p>,
          color: 'success',
          iconType: 'check',
        });
        setTrigger((prev) => prev + 1);
      } else {
        addToast({
          title: 'Fehler beim erstellen der Päsenation!',
          text: <p>{JSON.stringify(response.data)}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      }
    } catch (err) {
      addToast({
        title: 'Fehler beim erstellen der Päsenation!',
        text: <p>{err || err}</p>,
        color: 'danger',
        iconType: 'cross',
      });
    }
  };
  const importUsers = async (
    users: {
      email: string;
      vorname: string;
      nachname: string;
      title: string;
      unternhmen: string;
      campaign: string;
    }[]
  ): Promise<void> => {
    try {
      const response = await Post({
        path: `users/import`,
        value: { users },
        token: keycloak?.token,
      });
      if (response.status === 500) {
        addToast({
          title: 'Fehler beim Erstellen des neuen Nutzers!',
          text: <p>{response.data}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      } else {
        addToast({
          title: 'Nutzer importiert!',
          text: <p>Alle Nutzer wurden erfolgreich importiert!</p>,
          color: 'success',
          iconType: 'check',
        });
        setTrigger((prev) => prev + 1);
      }
    } catch (err) {
      addToast({
        title: 'Fehler beim Importieren der neuen Nutzer!',
        text: <p>{err || 'An error occurred'}</p>,
        color: 'danger',
        iconType: 'cross',
      });
    }
  };

  const createNewsletter = async (newsletter: Newsletter) => {
    if (
      !newsletter.name.trim() ||
      !newsletter.sender.trim() ||
      !newsletter.text.trim() ||
      !newsletter.from_email.trim() ||
      !newsletter.interval
    ) {
      addToast({
        title: 'Fehler',
        text: <p>Alle Felder müssen ausgefüllt sein!</p>,
        color: 'danger',
        iconType: 'cross',
      });
    } else {
      Post({ path: `newsletter/create`, value: newsletter, token: keycloak?.token })
        .then((response) => {
          if (response.status === 500) {
            addToast({
              title: 'Fehler beim erstellen des neuen Newsletters!',
              text: <p>{response.data}</p>,
              color: 'danger',
              iconType: 'cross',
            });
          } else {
            addToast({
              title: 'Newsletter erstellt!',
              text: <p>Der Newsletter{newsletter.name} wurde erfolgreich erstellt!</p>,
              color: 'success',
              iconType: 'check',
            });
            setTrigger((prev) => prev + 1);
          }
        })
        .catch((err) => {
          addToast({
            title: 'Fehler beim erstellen des neuen Newsletters!',
            text: <p>{err}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        });
    }
  };

  const addUserToNewsletter = (user: string, newsletter: string) => {
    if (user === undefined || newsletter === undefined) {
      addToast({
        title: 'Fehler',
        text: <p>Alle Felder müssen ausgefüllt sein!</p>,
        color: 'danger',
        iconType: 'cross',
      });
      return;
    } else {
      Post({
        path: `users/${user}/newsletter/${newsletter}/add`,
        value: { email: user },
        token: keycloak?.token,
      })
        .then((response) => {
          if (response.status === 500) {
            addToast({
              title: 'Fehler beim der zuweisung des Nutzers!',
              text: <p>{response.data}</p>,
              color: 'danger',
              iconType: 'cross',
            });
          } else {
            setTrigger((prev) => prev + 1);
            addToast({
              title: 'Nutzer erstellt!',
              text: <p>Der Nutzer wurde erfolgreich zum Newsletter hinzugefügt!</p>,
              color: 'success',
              iconType: 'check',
            });
          }
        })
        .catch((err) => {
          addToast({
            title: 'Fehler beim der zuweisung des Nutzers!',
            text: <p>{err}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        });
    }
  };

  const updateNewsletter = (params: Newsletter) => {
    Put({ path: `newsletter/${params.id}/update`, value: params, token: keycloak?.token })
      .then((response) => {
        if (response.status === 500) {
          addToast({
            title: 'Fehler beim updaten des Newsletters!',
            text: <p>{response.data}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        } else {
          setTrigger((prev) => prev + 1);
          addToast({
            title: 'Nutzer erstellt!',
            text: <p>Der Newsletter wurde erfolgreich angepasst!</p>,
            color: 'success',
            iconType: 'check',
          });
        }
      })
      .catch((err) => {
        addToast({
          title: 'Fehler beim updaten des Newsletters!',
          text: <p>{err}</p>,
          color: 'danger',
          iconType: 'cross',
        });
      });
  };

  const createPartner = async (name: string) => {
    if (!name.trim()) {
      addToast({
        title: 'Fehler',
        text: <p>Alle Felder müssen ausgefüllt sein!</p>,
        color: 'danger',
        iconType: 'cross',
      });
    } else {
      Post({ path: `companies/partner`, value: { name }, token: keycloak?.token })
        .then((response) => {
          if (response.status === 500) {
            addToast({
              title: 'Fehler beim erstellen des neuen Partneres!',
              text: <p>{response.data}</p>,
              color: 'danger',
              iconType: 'cross',
            });
          } else {
            addToast({
              title: 'Partner erstellt!',
              text: <p>Der Partner {name} wurde erfolgreich erstellt!</p>,
              color: 'success',
              iconType: 'check',
            });
            setTrigger((prev) => prev + 1);
          }
        })
        .catch((err) => {
          addToast({
            title: 'Fehler beim erstellen des neuen Partneres!',
            text: <p>{err}</p>,
            color: 'danger',
            iconType: 'cross',
          });
        });
    }
  };

  useEffect(() => {
    if (keycloak && keycloak.token && location.pathname.startsWith('/newsletter')) {
      getCompanies();
      getCampaign();
      getCustomers();
      getNewsletters();
      getMailUsers();
      getScannedMailbox();
      getPartners();
      getEvents();
      getPresentations();
    }
  }, [keycloak, keycloak?.token, location.pathname, trigger]);

  return (
    <NewsletterContext.Provider
      value={{
        companies,
        campaign,
        customers,
        newsletters,
        mailUsers,
        scannedMailbox,
        partners,
        events,
        presentations,
        deleteNewsletter,
        deleteUser,
        createUser,
        importUsers,
        createNewsletter,
        addUserToNewsletter,
        updateNewsletter,
        getPreview,
        createPartner,
        createPartnerUser,
        createEvents,
        assignPartnerToEvent,
        createEventPresentation,
        deletePartner,
        deleteEvent,
        getPublicPresentations,
        getUserEventDetails,
        getEventUsers,
        getEventPartner,
      }}
    >
      {children}
    </NewsletterContext.Provider>
  );
};

export const useNewsletter = () => {
  const context = useContext(NewsletterContext);
  if (context === undefined) {
    throw new Error('useNewsletter must be used within a NewletterProvider');
  }
  return context;
};
