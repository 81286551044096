import React, { useState, useEffect, useCallback, createContext, useContext, useMemo } from 'react';
import Keycloak, { KeycloakConfig } from 'keycloak-js';
import * as Sentry from '@sentry/react';
import { WarningPage } from '../../components/pages/warning';
import { useLocation } from 'react-router';
import { KeycloakContextProviderProps, Profile, TKeycloakContext } from '../../_types';
import { KeycloakClient } from '../../_types/questionnaires';

export const KeycloakContext = createContext<TKeycloakContext>({
  profile: undefined,
  keycloak: undefined,
  anketaRoles: [],
});

const defaultKeycloakConfig: KeycloakConfig = {
  url: 'https://keycloak.autonubil.net/auth',
  realm: 'autonubil',
  clientId: KeycloakClient,
};

export const useKeycloak = (): TKeycloakContext => {
  const context = useContext(KeycloakContext);
  if (!context) {
    throw new Error('useKeycloakContext must be used within a KeycloakProvider');
  }
  return context;
};

const useKeycloakAuth = () => {
  const [profile, setProfile] = useState<Profile | undefined>();
  const [keycloak, setKeycloak] = useState<Keycloak | undefined>();
  const location = useLocation();

  const setUserInfo = useCallback((info: Profile | null): void => {
    if (info) {
      Sentry.setUser(info as any);
      Sentry.captureMessage('Session started');
      setProfile(info);
    } else {
      Sentry.setUser(null);
      setProfile(undefined);
    }
  }, []);

  const handleSetProfile = useCallback(
    (profile: Profile | null): void => {
      const merged = profile ? ({ ...profile } as any) : null;
      if (merged) {
        delete merged.userProfileMetadata;
        delete merged.attributes;
        setUserInfo(merged);
      } else {
        setUserInfo(null);
      }
    },
    [setUserInfo]
  );

  const fetchProfile = useCallback(
    (keycloak: Keycloak): void => {
      keycloak
        .loadUserProfile()
        .then((profile) => {
          handleSetProfile(profile);
        })
        .catch(() => {
          console.error('[Keycloak] Failed to load profile');
          handleSetProfile(null);
        });
    },
    [handleSetProfile]
  );

  const initKeycloak = useCallback(
    (cfg: any): Keycloak => {
      const kc = new Keycloak(cfg);

      kc.onAuthSuccess = () => {
        console.debug('keycloak', 'Auth Success');
        kc.updateToken(30)
          .then(() => {
            // console.log('[Keycloak] keycloak Access successfully set');
          })
          .catch((err: any) => {
            console.error('[Keycloak]', err);
          });
      };

      kc.onAuthError = (errorData) => {
        console.warn('[Keycloak] auth error', JSON.stringify(errorData));
      };

      kc.onTokenExpired = () => {
        // console.debug('[Keycloak] keycloak Access token expired.', kc.token);
        kc.updateToken(30)
          .then(() => {
            // console.log('[Keycloak] keycloak Access successfully get updated', kc.token);
          })
          .catch((err) => {
            console.error('[Keycloak] Token error', err);
          });
      };

      kc.onActionUpdate = (status) => {
        switch (status) {
          case 'success':
            console.log('[Keycloak]', 'Action completed successfully');
            break;
          case 'cancelled':
            console.log('[Keycloak]', 'Action cancelled by user');
            break;
          case 'error':
            console.log('[Keycloak]', 'Action failed');
            break;
        }
      };

      kc.init({
        responseMode: 'fragment',
        flow: 'standard',
        enableLogging: true,
      })
        .then(async (authenticated) => {
          // console.log(
          //   '[Keycloak] Init Success (' +
          //     (authenticated ? 'Authenticated' : 'Not Authenticated') +
          //     ')',
          //   kc.token
          // );
          setKeycloak(kc);
          if (!authenticated) {
            if (location.pathname.startsWith('/public/')) {
              console.log('No auth requierd...');
            } else {
              await kc.login();
            }
          } else {
            fetchProfile(kc);
          }
        })
        .catch((err: any) => {
          handleSetProfile(null);
          console.warn('[Keycloak] profile error', err);
        });

      return kc;
    },
    [fetchProfile, handleSetProfile, location]
  );

  useEffect(() => {
    if (!keycloak) {
      initKeycloak(defaultKeycloakConfig);
      // fetch('api/keycloak.json');
      // .then((res) => res.json())
      // .then((cfg) => initKeycloak(cfg))
      // .catch(() => initKeycloak(defaultKeycloakConfig));
    } else if (!profile) {
      fetchProfile(keycloak);
    }
  }, [keycloak, profile, fetchProfile, initKeycloak]);

  return { profile, keycloak };
};

export const KeycloakAuth = ({ children }: KeycloakContextProviderProps): JSX.Element => {
  const { profile, keycloak } = useKeycloakAuth();
  const [anketaRoles, setAnketaRoles] = useState<string[]>([]);

  const contextValue = useMemo(
    () => ({ profile, keycloak, anketaRoles }),
    [profile, keycloak, anketaRoles]
  );

  useEffect(() => {
    if (keycloak && keycloak.resourceAccess && keycloak.resourceAccess[KeycloakClient]) {
      setAnketaRoles(keycloak.resourceAccess[KeycloakClient].roles);
    }
  }, [keycloak]);
  // if (profile !== undefined && token !== undefined) {
  return (
    <KeycloakContext.Provider value={contextValue}>
      <Sentry.ErrorBoundary
        fallback={({ error }: { error: any }) => <WarningPage error={error.message} />}
        showDialog
        dialogOptions={{
          user: {
            email: profile?.email,
            name: profile?.username,
          },
        }}
      >
        {children}
      </Sentry.ErrorBoundary>
    </KeycloakContext.Provider>
  );
  // }

  // return <WarningPage error="511" />;
};
