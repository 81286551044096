import Reacty from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer } from '@elastic/eui';
import { AuthorizeItem, useKeycloak } from '../../_functions';
import { useParams } from 'react-router';
import { CatalogCard } from '../../components';
import { QuestinaryFooter } from './footer';
import { KeycloakRolePrefix } from '../../_types/questionnaires';

export const QuestinaryBody = (): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { catalog, questionarie } = useParams();

  return (
    <AuthorizeItem
      key={`questionaries:body`}
      ctx={keycloak}
      roleName={`${KeycloakRolePrefix}-${catalog}-${questionarie}`}
    >
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <CatalogCard />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexItem>
        <EuiSpacer />
        <QuestinaryFooter />
      </EuiFlexItem>
    </AuthorizeItem>
  );
};
