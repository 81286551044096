import {
  Translation,
  Row,
  NumericQuestion,
  OpenQuestion,
  DateQuestion,
  TextValidator,
  TextValidatorType,
  ComplexQuestion,
  CatalogObject,
  Group,
  OptionsQuestion,
  ComplexFactory,
  MarkdownValidator,
} from 'anketa-core';
import { KeycloakInstance } from 'keycloak-js';
import { MedoOptionMap, MedoUser } from '../helpers';
import { generateActions } from '../opsanio/actions';

export class newcustomerCatalog extends CatalogObject {
  private static _instance: newcustomerCatalog;

  public static get instance(): newcustomerCatalog {
    if (!newcustomerCatalog._instance) {
      throw new Error(
        'newcustomerCatalog instance has not been created. Use createInstance(name) method first.'
      );
    }
    return newcustomerCatalog._instance;
  }

  public static createInstance(
    name: string,
    changeListener: () => void,
    authCtx: KeycloakInstance
  ): newcustomerCatalog {
    if (!newcustomerCatalog._instance) {
      newcustomerCatalog._instance = new newcustomerCatalog(name, changeListener, authCtx);
    }
    return newcustomerCatalog._instance;
  }

  constructor(name: string, changeListener: () => void, authCtx: KeycloakInstance) {
    const id: string = name.toLowerCase().replace(/ /g, '-');
    super(id, [new Translation('en', name), new Translation('de', name)]);

    // HEADER
    const gHeader = new Group('basic', [
      new Translation('en', 'General data'),
      new Translation('de', 'Allgemeine Daten'),
    ]);
    const headerRow = new Row('header', [
      new Translation('en', 'Customer Profile'),
      new Translation('de', 'Kundensteckbrief'),
    ]);
    const companyName = new OpenQuestion('name', [
      new Translation('en', 'Company Name'),
      new Translation('de', 'Firmenname'),
    ]);
    companyName.setFactValue(name);
    headerRow.addChild(companyName);

    const industry = new OpenQuestion('industry', [
      new Translation('en', 'Industry'),
      new Translation('de', 'Branche'),
    ]);
    headerRow.addChild(industry);
    const qWebUrl = new OpenQuestion('website', [
      new Translation('en', 'Website'),
      new Translation('de', 'Website'),
    ]);
    qWebUrl.validator = new TextValidator(TextValidatorType.WebUrl);
    qWebUrl.placeholder = [new Translation('en', 'URL'), new Translation('de', 'URL')];
    qWebUrl.description = [new Translation('en', 'Web URL'), new Translation('de', 'Web URL')];
    qWebUrl.help = [
      new Translation('en', `Link to a the website from ${name}`),
      new Translation('de', `Link auf die Website von ${name}`),
    ];
    headerRow.addChild(qWebUrl);

    const employees = new NumericQuestion('employees', [
      new Translation('en', 'Employees'),
      new Translation('de', 'Mitarbeiter'),
    ]);
    headerRow.addChild(employees);

    const employeesIt = new NumericQuestion('employees_it', [
      new Translation('en', 'Employees IT'),
      new Translation('de', 'Mitarbeiter IT'),
    ]);
    headerRow.addChild(employeesIt);

    const kritis = new OptionsQuestion(
      'kritis',
      [new Translation('en', 'KRITIS'), new Translation('de', 'KRITIS')],
      'default.options.yes_no'
    );
    headerRow.addChild(kritis);

    gHeader.addChild(headerRow);
    this.addChild(gHeader);

    // LOCATIONS
    const gLocations = new Group('locations_group', [
      new Translation('en', 'Locations'),
      new Translation('de', 'Standorte'),
    ]);
    const rLocations = new Row('locations_row_0', [
      new Translation('en', 'Locations'),
      new Translation('de', 'Standorte'),
    ]);
    gLocations.addChild(rLocations);

    const generateLocations = () => {
      const oldTrigger: ComplexQuestion = gLocations.children
        .get(`locations_row_${gLocations.children.size - 1}`)
        ?.children.get(`locations_${gLocations.children.size - 1}`) as ComplexQuestion;
      oldTrigger.removeChangeListener(generateLocations);

      const item = new ComplexQuestion(
        `locations_${rLocations.children.size}`,
        [
          new Translation('en', `Locations ${rLocations.children.size}`),
          new Translation('de', `Standorte ${rLocations.children.size}`),
        ],
        'Location'
      );
      item.addChangeListener(generateLocations);
      rLocations.addChild(item);
    };

    const locationZero = new ComplexQuestion(
      'locations_0',
      [new Translation('en', 'Main Site'), new Translation('de', 'Hauptstandorte')],
      'Location'
    );
    rLocations.addChild(locationZero);
    locationZero.addChangeListener(generateLocations);

    this.addChild(gLocations);

    // INTERNAL CONTACT
    const gGeneralInternalContact = new Group('internal_contact', [
      new Translation('en', 'Internal Contact'),
      new Translation('de', 'Interner Kontakt'),
    ]);
    const generalInternalContactRow = new Row('internal_contact_0', [
      new Translation('en', 'Internal Contact 1'),
      new Translation('de', 'Interner Kontakt 1'),
    ]);

    const generateInternalContact = () => {
      const oldTrigger: OpenQuestion = gGeneralInternalContact.children
        .get(`internal_contact_${gGeneralInternalContact.children.size - 1}`)
        ?.children.get(
          `internal_contact_tel_${gGeneralInternalContact.children.size - 1}`
        ) as OpenQuestion;
      oldTrigger.removeChangeListener(generateInternalContact);

      const item = new Row(`internal_contact_${gGeneralInternalContact.children.size}`, [
        new Translation('en', `${gGeneralInternalContact.children.size + 1}. Internal Contact`),
        new Translation('de', `${gGeneralInternalContact.children.size + 1}. Interner Kontakt`),
      ]);
      item.addChild(
        new OpenQuestion(`internal_contact_name_${gGeneralInternalContact.children.size}`, [
          new Translation('en', 'Name'),
          new Translation('de', 'Name'),
        ])
      );

      const itemName = new OpenQuestion(
        `internal_contact_email_${gGeneralInternalContact.children.size}`,
        [new Translation('en', 'E-Mail'), new Translation('de', 'E-Mail')]
      );
      item.addChild(itemName);

      const itemTel = new OpenQuestion(
        `internal_contact_tel_${gGeneralInternalContact.children.size}`,
        [new Translation('en', 'Telephone'), new Translation('de', 'Telefon')]
      );
      itemTel.addChangeListener(generateInternalContact);
      item.addChild(itemTel);

      gGeneralInternalContact.addChild(item);
    };

    gGeneralInternalContact.addChild(generalInternalContactRow);

    const internalContactName = new OpenQuestion('internal_contact_name_0', [
      new Translation('en', 'Name'),
      new Translation('de', 'Name'),
    ]);
    generalInternalContactRow.addChild(internalContactName);

    const internalContactEmail = new OpenQuestion('internal_contact_email_0', [
      new Translation('en', 'E-Mail'),
      new Translation('de', 'E-Mail'),
    ]);
    generalInternalContactRow.addChild(internalContactEmail);

    const internalContactTel = new OpenQuestion('internal_contact_tel_0', [
      new Translation('en', 'Telephone'),
      new Translation('de', 'Telefon'),
    ]);
    internalContactTel.addChangeListener(generateInternalContact);
    generalInternalContactRow.addChild(internalContactTel);

    this.addChild(gGeneralInternalContact);

    // CUSTOMER CONTACT
    ComplexFactory.registerClass({
      name: 'MedoUser',
      Constructor: MedoUser as new (init?: any) => MedoUser,
      form: MedoUser.getForm,
    });

    const gCustomerContact = new Group('customer_contact', [
      new Translation('en', 'Customer Contact'),
      new Translation('de', 'Kundenkontakt'),
    ]);
    const customerContactRow = new Row('customer_contact_0', [
      new Translation('en', 'Customer Contact'),
      new Translation('de', 'Kundenkontakt'),
    ]);
    const generateExternal = () => {
      const oldTrigger: ComplexQuestion = gCustomerContact.children
        .get(`customer_contact_${gCustomerContact.children.size - 1}`)
        ?.children.get(`user_customer_${gCustomerContact.children.size - 1}`) as ComplexQuestion;
      oldTrigger.removeChangeListener(generateExternal);

      const item = new ComplexQuestion(
        `user_customer_${customerContactRow.children.size}`,
        [
          new Translation('en', `Customer Contact ${customerContactRow.children.size}`),
          new Translation('de', `Kundenkontakt ${customerContactRow.children.size}`),
        ],
        'MedoUser'
      );
      item.addChangeListener(generateExternal);
      customerContactRow.addChild(item);
    };

    const customer = new ComplexQuestion(
      'user_customer_0',
      [new Translation('en', 'Customer Contact'), new Translation('de', 'Kundenkontakt')],
      'MedoUser'
    );
    customer.addChangeListener(generateExternal);
    customerContactRow.addChild(customer);
    gCustomerContact.addChild(customerContactRow);
    this.addChild(gCustomerContact);

    // CURRENT BUSINESS RELATINSHIP
    const businessRelationshipGroup = new Group('current_business_relationship', [
      new Translation('en', 'Current Business Relationship'),
      new Translation('de', 'Aktuelle Geschäftliche Beziehung'),
    ]);
    const businessRelationshipRow = new Row('current_business_relationship_0', [
      new Translation('en', 'Current Business Relationship'),
      new Translation('de', 'AktuelleGeschäftliche Beziehung'),
    ]);

    const generateBuisnessRelationship = () => {
      const oldTrigger: DateQuestion = businessRelationshipGroup.children
        .get(`current_business_relationship_${businessRelationshipGroup.children.size - 1}`)
        ?.children.get(`since_${businessRelationshipGroup.children.size - 1}`) as DateQuestion;
      oldTrigger.removeChangeListener(generateBuisnessRelationship);

      const item = new Row(
        `current_business_relationship_${businessRelationshipGroup.children.size}`,
        [
          new Translation(
            'en',
            `Current Business Relationship ${businessRelationshipGroup.children.size}`
          ),
          new Translation(
            'de',
            `AktuelleGeschäftliche Beziehung ${gBasicEnvironment.children.size}`
          ),
        ]
      );
      item.addChild(
        new OptionsQuestion(
          `business_division_${businessRelationshipGroup.children.size}`,
          [new Translation('en', 'Business Division'), new Translation('de', 'Geschäftsbereich')],
          MedoOptionMap.tech_category
        )
      );
      item.addChild(
        new OpenQuestion(`subarea_${businessRelationshipGroup.children.size}`, [
          new Translation('en', 'Subarea'),
          new Translation('de', 'Teilbereich'),
        ])
      );

      const itemDate = new DateQuestion(`since_${businessRelationshipGroup.children.size}`, [
        new Translation('en', 'Since'),
        new Translation('de', 'Seit'),
      ]);
      item.addChild(itemDate);

      itemDate.addChangeListener(generateBuisnessRelationship);
      businessRelationshipGroup.addChild(item);
    };

    const businessDivision = new OptionsQuestion(
      `business_division_0`,
      [new Translation('en', 'Business Division'), new Translation('de', 'Geschäftsbereich')],
      MedoOptionMap.tech_category
    );
    businessRelationshipRow.addChild(businessDivision);

    const subarea = new OpenQuestion('subarea', [
      new Translation('en', 'Subarea'),
      new Translation('de', 'Teilbereich'),
    ]);
    businessRelationshipRow.addChild(subarea);

    const since = new DateQuestion('since_0', [
      new Translation('en', 'Since'),
      new Translation('de', 'Seit'),
    ]);
    since.addChangeListener(generateBuisnessRelationship);
    businessRelationshipRow.addChild(since);
    businessRelationshipGroup.addChild(businessRelationshipRow);

    this.addChild(businessRelationshipGroup);

    // BASIC ENVIORMENT
    const gBasicEnvironment = new Group('basic_environment', [
      new Translation('en', 'Current Environment'),
      new Translation('de', 'Aktuelle Umgebung'),
    ]);
    const basicEnvironmentRow = new Row('basic_environment_0', [
      new Translation('en', 'Current Environment'),
      new Translation('de', 'Aktuelle Umgebung'),
    ]);

    const generateBasicEvent = () => {
      const oldTrigger: OptionsQuestion = gBasicEnvironment.children
        .get(`basic_environment_${gBasicEnvironment.children.size - 1}`)
        ?.children.get(`our_buisness_${gBasicEnvironment.children.size - 1}`) as OptionsQuestion;
      oldTrigger.removeChangeListener(generateBasicEvent);
      const item = new Row(`basic_environment_${gBasicEnvironment.children.size}`, [
        new Translation('en', `Basic Environment ${gBasicEnvironment.children.size}`),
        new Translation('de', `Grundeinstellung ${gBasicEnvironment.children.size}`),
      ]);
      item.addChild(
        new OptionsQuestion(
          `components_${gBasicEnvironment.children.size}`,
          [new Translation('en', 'Components'), new Translation('de', 'Komponenten')],
          MedoOptionMap.tech_components
        )
      );
      item.addChild(
        new OpenQuestion(`manufacturer_${gBasicEnvironment.children.size}`, [
          new Translation('en', 'Manufacturer'),
          new Translation('de', 'Hersteller'),
        ])
      );

      const itemDate = new OpenQuestion(`comment_be_${gBasicEnvironment.children.size}`, [
        new Translation('en', 'Comment'),
        new Translation('de', 'Kommentar'),
      ]);
      item.addChild(itemDate);

      const itemBuisnes = new OptionsQuestion(
        `our_buisness_${gBasicEnvironment.children.size}`,
        [new Translation('en', 'Our Buisness'), new Translation('de', 'Durch uns bedient')],
        MedoOptionMap.yes_no
      );
      itemBuisnes.addChangeListener(generateBasicEvent);
      item.addChild(itemBuisnes);

      gBasicEnvironment.addChild(item);
    };

    const components = new OptionsQuestion(
      'components',
      [new Translation('en', 'Components'), new Translation('de', 'Komponenten')],
      MedoOptionMap.tech_components
    );
    basicEnvironmentRow.addChild(components);

    const manufacturer = new OpenQuestion('manufacturer', [
      new Translation('en', 'Manufacturer'),
      new Translation('de', 'Hersteller'),
    ]);
    basicEnvironmentRow.addChild(manufacturer);

    const comment = new OpenQuestion('comment_be_0', [
      new Translation('en', 'Comment'),
      new Translation('de', 'Kommentar'),
    ]);
    basicEnvironmentRow.addChild(comment);

    const ourBusines = new OptionsQuestion(
      'our_buisness_0',
      [new Translation('en', 'Our Buisness'), new Translation('de', 'Durch uns bedient')],
      MedoOptionMap.yes_no
    );
    ourBusines.addChangeListener(generateBasicEvent);
    basicEnvironmentRow.addChild(ourBusines);

    gBasicEnvironment.addChild(basicEnvironmentRow);
    this.addChild(gBasicEnvironment);

    // GENERAL / SPECIAL FEATURES
    const gGeneralSpecialFeatures = new Group('general_special_features', [
      new Translation('en', 'General / Special Features'),
      new Translation('de', 'Allgemeines / Spezielle Funktionen'),
    ]);
    const generalSpecialFeaturesRow = new Row('general_special_features_0', [
      new Translation('en', 'General / Special Features'),
      new Translation('de', 'Allgemeines / Spezielle Funktionen'),
    ]);

    const generateSpecialFeatures = () => {
      const oldTrigger: OpenQuestion = gGeneralSpecialFeatures.children
        .get(`general_special_features_${gGeneralSpecialFeatures.children.size - 1}`)
        ?.children.get(`comment_acp_${gGeneralSpecialFeatures.children.size - 1}`) as OpenQuestion;
      oldTrigger.removeChangeListener(generateSpecialFeatures);

      const item = new Row(`general_special_features_${gGeneralSpecialFeatures.children.size}`, [
        new Translation('en', `Basic Environment ${gGeneralSpecialFeatures.children.size}`),
        new Translation('de', `Grundeinstellung ${gGeneralSpecialFeatures.children.size}`),
      ]);
      item.addChild(
        new OpenQuestion(`area_contact_person_${gGeneralSpecialFeatures.children.size}`, [
          new Translation('en', 'Area Contact Person'),
          new Translation('de', 'Ansprechpartner'),
        ])
      );

      const itemComment = new OpenQuestion(`comment_acp_${gGeneralSpecialFeatures.children.size}`, [
        new Translation('en', 'Comment'),
        new Translation('de', 'Kommentar'),
      ]);
      itemComment.validator = new MarkdownValidator();
      itemComment.addChangeListener(generateSpecialFeatures);

      item.addChild(itemComment);
      gGeneralSpecialFeatures.addChild(item);
    };

    gGeneralSpecialFeatures.addChild(generalSpecialFeaturesRow);

    const AreaContactPerson = new OpenQuestion('area_contact_person', [
      new Translation('en', 'Area Contact Person'),
      new Translation('de', 'Area Contact Person'),
    ]);
    generalSpecialFeaturesRow.addChild(AreaContactPerson);

    const commentAreaContactPerson = new OpenQuestion('comment_acp_0', [
      new Translation('en', 'Comment'),
      new Translation('de', 'Kommentar'),
    ]);
    commentAreaContactPerson.addChangeListener(generateSpecialFeatures);
    commentAreaContactPerson.validator = new MarkdownValidator();
    generalSpecialFeaturesRow.addChild(commentAreaContactPerson);

    this.addChild(gGeneralSpecialFeatures);

    // ACTIONS
    this.addChild(generateActions(`newcustomer_${id}`, changeListener, authCtx));
  }
}
