import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import '@elastic/eui/dist/eui_theme_light.min.css';

// Make sure all locales are loaded
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/de-ch';
import 'moment/locale/de-at';
import { KeycloakAuth } from './_functions/auth';

import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from './_functions/toast';
import { NewletterProvider } from './_functions';
import { SupportProvider } from './_functions/support/support';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { QuestionarieProvider } from './_questionnaires';
import { Navigation } from './_home/navigation';

const msalConfiguration = {
  auth: {
    clientId: 'e32feb06-de0d-4371-b895-36adca6c718b',
    authority: 'https://login.microsoftonline.com/8495880e-0ea4-433e-936a-8e183898c1af',
  },
};

const pca = new PublicClientApplication(msalConfiguration);

ReactDOM.render(
  <Router>
    <KeycloakAuth>
      <ToastProvider>
        <QuestionarieProvider>
          <NewletterProvider>
            {/* <MsalProvider instance={pca}> */}
            {/* <SupportProvider> */}
            <Navigation />
            {/* </SupportProvider> */}
            {/* </MsalProvider> */}
          </NewletterProvider>
        </QuestionarieProvider>
      </ToastProvider>
    </KeycloakAuth>
  </Router>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
