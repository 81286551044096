import * as React from 'react';

export class AutonubilSvg extends React.Component<React.SVGProps<SVGSVGElement>> {
  render() {
    const { ...props } = this.props; // Spread props for proper usage
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={592.119}
        height={455.712}
        viewBox="0 0 156.665 120.574"
        {...props}
      >
        <g
          style={{
            stroke: 'none',
            strokeOpacity: 1,
          }}
        >
          <g
            style={{
              stroke: 'none',
              strokeOpacity: 1,
            }}
          >
            <path
              d="m0 0-10.089 10.09a.918.918 0 0 1-1.296 0L-21.475 0a.91.91 0 0 1-.268-.648.91.91 0 0 1 .268-.648l10.089-10.089a.915.915 0 0 1 1.297 0l1.886 1.886-1.03 1.031-1.505-1.504-9.324 9.324 9.325 9.324 9.324-9.323-4.557-4.559 1.03-1.03L0-1.296a.91.91 0 0 1 .268.648A.91.91 0 0 1 0 0"
              style={{
                fill: '#79c9d9',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
                strokeOpacity: 1,
              }}
              transform="matrix(5.29598 0 0 -5.29598 117.15 56.854)"
            />
          </g>
          <g
            style={{
              stroke: 'none',
              strokeOpacity: 1,
            }}
          >
            <path
              d="m0 0-1.542-1.543.343-.343L.305-.382l10.011-10.012L.305-20.406-9.707-10.394l4.558 4.558-.344.343-4.596-4.597a.43.43 0 0 1 0-.609L0-20.788a.43.43 0 0 1 .609 0l10.09 10.089a.433.433 0 0 1 0 .609L.609 0A.433.433 0 0 1 0 0"
              style={{
                fill: '#04a0af',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
                strokeOpacity: 1,
              }}
              transform="matrix(5.29598 0 0 -5.29598 97.34 5.24)"
            />
          </g>
        </g>
      </svg>
    );
  }
}
