import React from 'react';
import {
  EuiButtonIcon,
  EuiCollapsibleNavGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
  EuiTreeView,
} from '@elastic/eui';
import { useNavigate } from 'react-router';
import { RouteQuestionarie, RouterAction } from '../../_functions/common';
import { useQuestionarie } from '../../_questionnaires';
import { KeycloakRolePrefix, QuestionarieActions } from '../../_types/questionnaires';
import { AuthorizeItem, useKeycloak } from '../../_functions';

export const QuestionarieNavGroup = (): JSX.Element => {
  const { questionaries, catalogs } = useQuestionarie();
  const navigate = useNavigate();
  const { keycloak, anketaRoles } = useKeycloak();

  if (catalogs.length !== 0 && anketaRoles.length > 1) {
    return (
      <EuiCollapsibleNavGroup title="Catalogs" isCollapsible={true} initialIsOpen={false}>
        <EuiSpacer size="s" />
        <EuiTreeView
          items={catalogs.map((c, index) => ({
            label: (
              <EuiFlexGroup direction="row" alignItems="center">
                <EuiFlexItem grow={false}>
                  <AuthorizeItem
                    key={`router:side:catalog:${c}`}
                    ctx={keycloak}
                    roleName={`${KeycloakRolePrefix}-${c}-admin`}
                  >
                    <EuiButtonIcon
                      iconType="listAdd"
                      size="s"
                      onClick={() => {
                        navigate(
                          `${RouteQuestionarie}/${c}?${RouterAction}=${QuestionarieActions.Create}`
                        );
                      }}
                    />
                  </AuthorizeItem>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    iconType="symlink"
                    size="s"
                    onClick={() => navigate(`${RouteQuestionarie}/${c}`)}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow>
                  <span>{c}</span>
                </EuiFlexItem>
              </EuiFlexGroup>
            ),
            id: `catalog:${c}:${index}`,
            icon: <EuiIcon type="arrowRight" />,
            iconWhenExpanded: <EuiIcon type="arrowDown" />,
            isExpanded: false,
            children: questionaries
              .filter((q) => q.catalog === c)
              .map((q, idx) => ({
                label: q.name,
                id: `catalog:${c}:${index}:${q.name}:${idx}`,
                icon: (
                  <EuiIcon
                    type="pencil"
                    onClick={() => {
                      navigate(`${RouteQuestionarie}/${c}/${q.name}`);
                    }}
                  />
                ),
                expandByDefault: false,
              })),
          }))}
          aria-label="Sample Tree View"
        />
      </EuiCollapsibleNavGroup>
    );
  } else {
    return <></>;
  }
};
