import React, { useContext } from 'react';
import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { AuthorizeItem, useApi, useKeycloak } from '../../_functions';
import { useParams } from 'react-router';
import { KeycloakRolePrefix } from '../../_types/questionnaires';
import { useQuestionarie } from '../context';
import { documentation } from '../functions';
import { AnketaContext } from '../../components';

export const QuestinaryFooter = (): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { catalog, questionarie } = useParams();
  const { createOrUpdate, currentCatalog } = useQuestionarie();
  const ctx = useContext(AnketaContext);
  const { Get, Post } = useApi();

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <AuthorizeItem
          key={`questionaries:footer:save`}
          ctx={keycloak}
          roleName={`${KeycloakRolePrefix}-${catalog}-${questionarie}-admin`}
        >
          <EuiButton
            fill
            size="s"
            onClick={() => {
              if (catalog && questionarie) {
                console.log(catalog, questionarie.toLowerCase(), currentCatalog.factsToObject());
                createOrUpdate(catalog, questionarie, currentCatalog);
              }
            }}
          >
            Speichern
          </EuiButton>
        </AuthorizeItem>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <AuthorizeItem
          key={`questionaries:footer:docu`}
          ctx={keycloak}
          roleName={`${KeycloakRolePrefix}-${catalog}-${questionarie}`}
        >
          <EuiButton
            fill
            size="s"
            onClick={() => {
              if (catalog && keycloak?.token) {
                documentation(
                  ctx.catalog.factsToObject(ctx.catalog, true),
                  catalog,
                  Get,
                  Post,
                  keycloak.token
                );
              }
            }}
          >
            Documentation
          </EuiButton>
        </AuthorizeItem>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
