export const APP_NAME = 'autonubil GmbH';
export const SERVER_URL = window.location.origin.includes('localhost')
  ? 'http://localhost:3011/'
  : '/';
export const UI_URL = window.location.origin.includes('localhost')
  ? 'http://localhost:3000/'
  : 'https://anketa.exanio.cloud/';

export const RouteQuestionarie = 'questionaries';

export const RouterAction = 'action';
