import React, { useState } from 'react';
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
  htmlIdGenerator,
} from '@elastic/eui';
import gravatar from 'gravatar';
import { useKeycloak } from '../_functions';

export const Avatar = (): JSX.Element => {
  const { profile, keycloak } = useKeycloak();
  const id = htmlIdGenerator()();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const AvatarCircle = (
    <EuiAvatar
      name={profile?.email && profile?.email ? profile?.email : ''}
      size="m"
      imageUrl={gravatar.url(profile?.email && profile?.email ? profile?.email : '', {
        s: '32',
        format: '404',
        r: 'pg',
      })}
    />
  );
  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={id}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      {AvatarCircle}
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiPopover
      id={id}
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="none"
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          className="EuiHeaderProfile"
          responsive={false}
          style={{
            padding: '15px',
          }}
        >
          <EuiFlexItem grow={false}>{AvatarCircle}</EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <h3>
                {profile?.firstName} {profile?.lastName}
              </h3>
            </EuiText>

            <EuiText>
              <p>{profile?.email}</p>
            </EuiText>
            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <EuiLink
                      onClick={() => {
                        keycloak?.logout();
                      }}
                    >
                      Log out
                    </EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
};
