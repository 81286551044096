import {
  CatalogObject,
  MarkdownValidator,
  OpenQuestion,
  Row,
  Section,
  Translation,
} from 'anketa-core';
import { EventDetectionQuestinary } from './types';
import { v4 as uuidv4 } from 'uuid';
import { KeycloakInstance } from 'keycloak-js';
import { generateActions } from './actions';

const generateStaticLengthId = (uuidLength: number = 24): string => {
  const rawUuid = uuidv4().replace(/-/g, '');
  const truncatedUuid = rawUuid.substring(0, uuidLength);
  const id = truncatedUuid;

  if (id.length > 32) {
    throw new Error(`Generated ID "${id}" exceeds the maximum length of 32 characters.`);
  }

  return id;
};

export class EventDetectionCatalog extends CatalogObject {
  private static _instance: EventDetectionCatalog;

  public static get instance(): EventDetectionCatalog {
    if (!EventDetectionCatalog._instance) {
      throw new Error(
        'EventDetectionCatalog instance has not been created. Use createInstance(name) method first.'
      );
    }
    return EventDetectionCatalog._instance;
  }

  public static createInstance(
    data?: EventDetectionQuestinary,
    changeListener?: () => void,
    authCtx?: KeycloakInstance
  ): EventDetectionCatalog {
    if (!EventDetectionCatalog._instance) {
      EventDetectionCatalog._instance = new EventDetectionCatalog(data, changeListener, authCtx);
    }
    return EventDetectionCatalog._instance;
  }

  constructor(
    data?: EventDetectionQuestinary,
    changeListener?: () => void,
    authCtx?: KeycloakInstance
  ) {
    const id: string = generateStaticLengthId(24);
    super(id, [
      new Translation('en', data?.customer.email || ''),
      new Translation('de', data?.customer.email || ''),
    ]);
    console.log('[QrCode] Catalog', id, data);

    // CUSTOMER
    const customerS = new Section('c_sections', [
      new Translation('en', 'Event'),
      new Translation('de', 'Event'),
    ]);
    const customer = new Row('customer', [
      new Translation('en', 'Customer'),
      new Translation('de', 'Customer'),
    ]);

    const customerName = new OpenQuestion('name', [
      new Translation('en', 'Name'),
      new Translation('de', 'Name'),
    ]);
    customerName.setFactValue(data?.customer.name);
    const customerEmail = new OpenQuestion('email', [
      new Translation('en', 'E-Mail'),
      new Translation('de', 'E-Mail'),
    ]);
    customerEmail.setFactValue(data?.customer.email);
    const customerCompany = new OpenQuestion('company', [
      new Translation('en', 'Company'),
      new Translation('de', 'Unternehmen'),
    ]);
    customerCompany.setFactValue(data?.customer.organization);

    const customerEvent = new OpenQuestion('event', [
      new Translation('en', 'Name'),
      new Translation('de', 'Name'),
    ]);
    customerEvent.setFactValue(data?.event);

    customer.addChild(customerName);
    customer.addChild(customerEmail);
    customer.addChild(customerCompany);
    customer.addChild(customerEvent);

    customerS.addChild(customer);
    this.addChild(customerS);

    // Partner
    const partnerS = new Section('s_p', [
      new Translation('en', 'Partner'),
      new Translation('de', 'Partner'),
    ]);
    const partner = new Row('pRow', [
      new Translation('en', 'Partner'),
      new Translation('de', 'Partner'),
    ]);
    const partnerContact = new OpenQuestion('contact', [
      new Translation('en', 'Contact'),
      new Translation('de', 'Kontakt'),
    ]);
    partnerContact.setFactValue(data?.partner.contact);
    const partnerPartner = new OpenQuestion('partner', [
      new Translation('en', 'Partner name'),
      new Translation('de', 'Partner Name'),
    ]);
    partnerPartner.setFactValue(data?.partner.name);

    partner.addChild(partnerContact);
    partner.addChild(partnerPartner);

    partnerS.addChild(partner);
    this.addChild(partnerS);

    // Message
    const msgS = new Section('s_m', [
      new Translation('en', 'Message'),
      new Translation('de', 'Nachricht'),
    ]);
    const message = new Row('message', [
      new Translation('en', 'Message'),
      new Translation('de', 'Nachricht'),
    ]);
    const messageNote = new OpenQuestion('note', [
      new Translation('en', 'Note'),
      new Translation('de', 'Notize'),
    ]);
    messageNote.setFactValue(data?.message);
    messageNote.validator = new MarkdownValidator();
    message.addChild(messageNote);

    msgS.addChild(message);
    if (changeListener && authCtx) {
      msgS.addChild(generateActions(`event_${id}`, changeListener));
    }
    this.addChild(msgS);
  }
}
