import {
  Group,
  MarkdownValidator,
  NumericQuestion,
  OpenQuestion,
  Row,
  Section,
  TextValidator,
  TextValidatorType,
  Translation,
} from 'anketa-core';
import { calculateHostRange, intToIp, ipToInt, isValidCIDR } from '../../functions';

const splitCIDR = (cidr: string) => {
  const [ip, prefixLength] = cidr.split('/');
  const prefix = parseInt(prefixLength, 10);
  const newPrefix = prefix + 1;

  const ipInt = ipToInt(ip);

  const firstSubnet = `${intToIp(ipInt & ~((1 << (32 - newPrefix)) - 1))}/${newPrefix}`;
  const secondSubnet = `${intToIp(
    (ipInt & ~((1 << (32 - newPrefix)) - 1)) + (1 << (32 - newPrefix))
  )}/${newPrefix}`;

  return [firstSubnet, secondSubnet];
};

export const generateAutonubil = (name: string, readonly: boolean) => {
  console.log('Exanio Read Only', readonly);
  const generateAgent = (agentNr: number, cpe: 'a' | 'b', offset: number) => {
    const exanioName = `opsanio-${project.fact.raw}-${stage.fact.raw}-a0-opsanio-00${agentNr}`;
    const parent = new Section(`agent_${agentNr}`, `opsanio-00${agentNr}`);
    const item = new Row(`agent_${agentNr}`, `opsanio-00${agentNr}`);

    const name = new OpenQuestion(`agent_${agentNr}_name`, 'Name');
    name.setFactValue(`${exanioName}`);
    name.readonly = true;
    item.addChild(name);

    const redfishIP = new OpenQuestion(`redfish_${agentNr}`, [
      new Translation('en', 'Managment IP'),
      new Translation('de', 'Managment IP'),
    ]);
    redfishIP.validator = new TextValidator(TextValidatorType.IP);
    redfishIP.readonly = readonly;
    item.addChild(redfishIP);

    const setupIP = new OpenQuestion(`setup_${agentNr}`, [
      new Translation('en', 'Setup IP'),
      new Translation('de', 'Setup IP'),
    ]);
    setupIP.readonly = readonly;
    setupIP.validator = new TextValidator(TextValidatorType.IP);
    item.addChild(setupIP);

    const snIP = new OpenQuestion(`sn_${agentNr}`, [
      new Translation('en', 'Serial Number'),
      new Translation('de', 'Serial Number'),
    ]);
    snIP.readonly = readonly;
    item.addChild(snIP);

    let trasnferIP;
    let cpeIP;

    const trasnferCIDR = trasnfer.children.get('network_transfer');

    if (trasnferCIDR instanceof OpenQuestion) {
      trasnferIP = calculateHostRange(trasnferCIDR.fact.raw as string, agentNr).specificHost;
    }
    if (cpe === 'b') {
      const cpeB = rNetworkCPE.children.get('cpe_b_net');
      if (cpeB instanceof Row) {
        const cpeBNetwork = cpeB.children.get('network_cpe_b');
        if (cpeBNetwork instanceof OpenQuestion) {
          cpeIP = calculateHostRange(cpeBNetwork.fact.raw as string, offset + 4).specificHost;
        }
      }
    }
    if (cpe === 'a') {
      const cpeA = rNetworkCPE.children.get('cpe_a_net');
      if (cpeA instanceof Row) {
        const cpeANetwork = cpeA.children.get('network_cpe_a');
        if (cpeANetwork instanceof OpenQuestion) {
          cpeIP = calculateHostRange(cpeANetwork.fact.raw as string, offset + 3).specificHost;
        }
      }
    }

    const qTrasnferIP = new OpenQuestion(`a_${agentNr}_tip`, [
      new Translation('en', 'Transfer IP'),
      new Translation('de', 'Transfer IP'),
    ]);
    if (trasnferIP) {
      qTrasnferIP.setFactValue(trasnferIP);
      qTrasnferIP.readonly = true;
    }
    item.addChild(qTrasnferIP);

    const qCpeIP = new OpenQuestion(`a_${agentNr}_cip`, [
      new Translation('en', 'CPE IP'),
      new Translation('de', 'CPE IP'),
    ]);
    if (cpeIP) {
      qCpeIP.setFactValue(cpeIP);
      qCpeIP.readonly = true;
    }

    item.addChild(qCpeIP);

    const customerIP = new OpenQuestion(`a_${agentNr}_cuip`, [
      new Translation('en', `Agent IP address in the customer network`),
      new Translation('de', `Agent IP address im Kunden Netwerk`),
    ]);
    item.addChild(customerIP);

    const dnsName = new OpenQuestion(`${agentNr}_dns`, [
      new Translation('en', 'DNS Names'),
      new Translation('de', 'DNS Names'),
    ]);
    dnsName.validator = new MarkdownValidator();
    dnsName.readonly = true;
    dnsName.setFactValue(`
- ${exanioName}
- ${exanioName}.exanio.net
      `);
    item.addChild(dnsName);

    parent.addChild(item);
    agnets.addChild(parent);
  };

  const generateExanioNetwork = (network: string, cidr?: string, vlan?: number) => {
    const id: string = network.toLowerCase().replace(/ /g, '_');

    const rNetworkTrans = new Row(`${id}_net`, [
      new Translation('en', 'autonubil Network'),
      new Translation('de', 'autonubil Network'),
    ]);

    const networkNameTrans = new OpenQuestion(`nn_${id}`, [
      new Translation('en', 'Network Name'),
      new Translation('de', 'Netzwerknamen'),
    ]);
    networkNameTrans.setFactValue(network);
    networkNameTrans.readonly = true;
    rNetworkTrans.addChild(networkNameTrans);

    const networkTrans = new OpenQuestion(`network_${id}`, [
      new Translation('en', 'Network'),
      new Translation('de', 'Netzwerk'),
    ]);
    networkTrans.readonly = readonly;

    networkTrans.validator = new TextValidator(TextValidatorType.CIDR);
    if (cidr) {
      networkTrans.setFactValue(cidr);
      networkTrans.readonly = readonly;
    }

    rNetworkTrans.addChild(networkTrans);

    const vlanTrans = new OpenQuestion(`vlan_${id}`, [
      new Translation('en', 'VLAN'),
      new Translation('de', 'VLAN'),
    ]);
    vlanTrans.readonly = readonly;

    vlanTrans.validator = new TextValidator(TextValidatorType.VLAN);
    if (vlan) {
      vlanTrans.setFactValue(vlan.toString());
    }
    rNetworkTrans.addChild(vlanTrans);

    return rNetworkTrans;
  };

  const generateCpeCidrs = () => {
    if (isValidCIDR(networkCPE.fact.raw as string)) {
      const twoRoomCidrs = splitCIDR(networkCPE.fact.raw as string);
      const shared_ip = new OpenQuestion(`cpe_shared`, [
        new Translation('eng', 'Shared IP-Address'),
        new Translation('de', 'Shared IP-Address'),
      ]);

      if (shared_ip instanceof OpenQuestion && networkCPE.fact.raw) {
        const sharedIp = calculateHostRange(networkCPE.fact.raw as string, 1).specificHost;
        shared_ip.setFactValue(sharedIp !== null ? sharedIp : '');
        shared_ip.readonly = true;
        rNetworkCPE.addChild(shared_ip);
      }

      twoRoomCidrs.forEach((cidr, idx) => {
        const item = generateExanioNetwork(
          idx === 0 ? `cpe_a` : `cpe_b`,
          cidr,
          vlanCPE.fact.raw as number
        );
        rNetworkCPE.addChild(item);
      });

      for (let i = 0; i < (agentCount.fact.raw as number); i++) {
        const type = i % 2 === 0 ? 'a' : 'b';
        const indexOffset = Math.floor(i / 2) + 1;

        generateAgent(i + 1, type, indexOffset);
      }
    }
  };

  const generateWireGuard = () => {
    const rWireguard = new Row('wg', [
      new Translation('en', 'WireGUard'),
      new Translation('de', 'WireGuard'),
    ]);

    const wireguardIp = new OpenQuestion(`wireguard_ip`, [
      new Translation('en', 'WireGuard IP'),
      new Translation('de', 'WireGuard IP'),
    ]);
    wireguardIp.setFactValue('88.205.28.70');
    wireguardIp.validator = new TextValidator(TextValidatorType.IP);
    wireguardIp.readonly = readonly;

    rWireguard.addChild(wireguardIp);

    const wireguardDns = new OpenQuestion(`wireguard_dns`, [
      new Translation('en', 'WireGuard DNS-Name'),
      new Translation('de', 'WireGuard DNS-Name'),
    ]);

    project.addChangeListener(() => {
      if (project.fact.raw !== undefined && stage.fact.raw !== undefined) {
        wireguardDns.setFactValue(`mekan.opsanio-${project.fact.raw}-${stage.fact.raw}.exanio.net`);
      }
    });
    stage.addChangeListener(() => {
      if (project.fact.raw !== undefined && stage.fact.raw !== undefined) {
        wireguardDns.setFactValue(`mekan.opsanio-${project.fact.raw}-${stage.fact.raw}.exanio.net`);
      }
    });
    wireguardDns.readonly = true;
    // wireguardDns.readonly = true;
    rWireguard.addChild(wireguardDns);

    const wireGuardPort = new NumericQuestion(`wireguard_port`, [
      new Translation('eng', 'WireGuard Port'),
      new Translation('de', 'WireGuard Port'),
    ]);

    networkSlot.addChangeListener(() => {
      wireGuardPort.setFactValue(`${parseInt(networkSlot.fact.raw as string) + 2000}`);
    });
    wireGuardPort.readonly = true;
    rWireguard.addChild(wireGuardPort);

    return rWireguard;
  };
  const autonubil = new Section('autonubil', [
    new Translation('en', 'autonubil'),
    new Translation('de', 'autonubil'),
  ]);

  const rHeaderCommon = new Row('commons', [
    new Translation('en', 'Common Tags'),
    new Translation('de', 'Common Tags'),
  ]);
  const owner = new OpenQuestion('owner', [
    new Translation('en', 'Owner'),
    new Translation('de', 'Owner'),
  ]);
  owner.setFactValue('opsanio');
  owner.readonly = true;
  rHeaderCommon.addChild(owner);

  const project = new OpenQuestion('project', [
    new Translation('en', 'Project'),
    new Translation('de', 'Projekt'),
  ]);
  project.readonly = readonly;
  rHeaderCommon.addChild(project);

  const stage = new OpenQuestion('stage', [
    new Translation('en', 'Stage'),
    new Translation('de', 'Stage'),
  ]);
  stage.readonly = readonly;

  rHeaderCommon.addChild(stage);

  const agentCount = new NumericQuestion(`agent_count`, [
    new Translation('en', 'Number of Agents'),
    new Translation('de', 'Anzahl an Agnets'),
  ]);
  agentCount.readonly = readonly;

  rHeaderCommon.addChild(agentCount);

  const networkSlot = new NumericQuestion(`network_slot`, [
    new Translation('en', 'Network slot'),
    new Translation('de', 'Network slot'),
  ]);
  networkSlot.readonly = readonly;

  networkSlot.validator = new TextValidator(TextValidatorType.VLAN);
  networkSlot.help = [
    new Translation('en', 'Enter the VLAN ID (a number between 1 and 4094).'),
    new Translation('de', 'Geben Sie die VLAN-ID ein (eine Zahl zwischen 1 und 4094).'),
  ];

  rHeaderCommon.addChild(networkSlot);

  autonubil.addChild(rHeaderCommon);

  const exanio = generateExanioNetwork('exanio');
  autonubil.addChild(exanio);
  const trasnfer = generateExanioNetwork('Transfer');

  autonubil.addChild(trasnfer);

  const rNetworkCPE = new Section('cpe_net', [
    new Translation('en', 'CPE Networks'),
    new Translation('de', 'CPE Networks'),
  ]);
  const networkNameCPE = new OpenQuestion('nn_cpe', [
    new Translation('en', 'Network Name'),
    new Translation('de', 'Netzwerknamen'),
  ]);
  networkNameCPE.validator = new TextValidator(TextValidatorType.Name);
  networkNameCPE.setFactValue('CPE');
  networkNameCPE.readonly = true;
  rNetworkCPE.addChild(networkNameCPE);
  const networkCPE = new OpenQuestion('network_cpe', [
    new Translation('en', 'Network'),
    new Translation('de', 'Netzwerk'),
  ]);
  networkCPE.validator = new TextValidator(TextValidatorType.CIDR);
  networkCPE.help = [
    new Translation('en', 'Enter the network in CIDR notation (e.g., 192.168.0.0/24).'),
    new Translation('de', 'Geben Sie das Netzwerk in CIDR-Notation ein (z.B., 192.168.0.0/24).'),
  ];
  networkCPE.readonly = readonly;
  rNetworkCPE.addChild(networkCPE);
  const vlanCPE = new OpenQuestion('vlan_cpe', [
    new Translation('en', 'VLAN'),
    new Translation('de', 'VLAN'),
  ]);
  vlanCPE.validator = new TextValidator(TextValidatorType.VLAN);
  vlanCPE.addChangeListener(generateCpeCidrs);
  vlanCPE.readonly = readonly;
  rNetworkCPE.addChild(vlanCPE);
  autonubil.addChild(rNetworkCPE);

  autonubil.addChild(generateWireGuard());

  const agnets = new Group('agents', [
    new Translation('en', 'Agents'),
    new Translation('de', 'Agents'),
  ]);
  autonubil.addChild(agnets);

  return autonubil;
};
