// TODO: Make anketa-core npm package public to build correct Types
// import { Catalog, Hierarchical } from 'anketa-core';

export const KeycloakRolePrefix = 'anketa';
export const KeycloakClient = `${KeycloakRolePrefix}-demo`;
export const isValidCatalog = (catalog: string): boolean => {
  const catalogArray = Object.values(QuestionarieCatalogs);
  return catalogArray.includes(catalog as QuestionarieCatalogs);
};

export interface QuestionarieContextType {
  questionaries: QuestionarieParams[];
  catalogs: string[];
  selectedQuestionarie: QuestionarieParams | null;
  setSelectedQuestionarie: (c: string | undefined, q: string | undefined) => void;
  // TODO: Make anketa-core npm package public to build correct Types
  // currentCatalog: Catalog;
  currentCatalog: any;
  createOrUpdate: (c: string, q: string, data?: any) => void;
  deleteQ: (c: string, q: string) => void;
}

export enum QuestionarieCatalogs {
  NewCustomer = 'newcustomer',
  ArcticWolf = 'arcticwolf',
  Opsanio = 'opsanio',
  VMware = 'vmware',
  BSIKatalog2023Edition = 'bsi_katalog_2023_edition',
  Onboarding = 'onboarding',
  Event = 'event',
}

export enum QuestionarieActions {
  Create = 'create',
  Delete = 'delete',
}

export type QuestionarieParams = {
  id: string;
  name: string;
  // TODO: Make anketa-core npm package public to build correct Types
  // content: Hierarchical;
  content: any;
  catalog: string;
  updated_by: string;
  last_updated: string;
};
